import { HISTORY_SLOT_ID } from '@constants/constants';
import { useHistoryLayout } from '@src/context/side-layout/useHistoryLayout';
import HistoryLayoutScope from 'react-modal-promise';

const HistoryLayoutContainer = () => {
  const { historyRef } = useHistoryLayout();

  return <HistoryLayoutScope ref={historyRef} scope={HISTORY_SLOT_ID} />;
};

export default HistoryLayoutContainer;
