import { useLazyQuery } from '@apollo/client';
import { HISTORY_SLOT_ID } from '@constants/constants';
import { CircularProgress, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ControlHistoryData, getControlsHistory } from '@src/media-server';
import {
  ControlExecutionsEdge,
  ControlExecutionsOrderBy,
  ControlTypes,
  GetPagedControlsQuery,
  Object as ObjectType,
  PageInfo,
} from '@src/__generated__/graphql';
import downloadBlob from '@utils/downloadBlob';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { format, formatISO, sub } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { create, InstanceProps } from 'react-modal-promise';
import { GET_PAGED_CONTROLS } from './api/getPagedControls';
import HistoryControlsItem from './components/HistoryControlsItem';
import HistoryControlsToolbar from './components/HistoryControlsToolbar';
import st from './style.module.css';

interface IProps {
  object: ObjectType;
  historyControlName?: string;
}

const HistoryControls: FC<IProps & InstanceProps<{}>> = (props) => {
  const { object, onResolve: onClose, historyControlName } = props;
  const id = object.id;
  const [recordedFilter, setRecordedFilter] = useState([
    formatISO(sub(Date.now(), { days: 1 })),
    formatISO(Date.now()),
  ]);

  const buttonCondition =
    object.schemaTags.includes(WIDGETS_ENUM.ADVANCED_BUTTON) || object.schemaTags.includes(WIDGETS_ENUM.COMMAND_BUTTON);

  function defineId(): string {
    if (buttonCondition) {
      const objectId = object.objectProperties.find((obj) => obj.key === 'valueValue')?.value?.objectId as string;
      const objectIdFromValueAction = object.objectProperties.find((obj) => obj.key === 'valueAction')?.value
        ?.objectId as string;
      return objectIdFromValueAction || objectId || id;
    }
    return id;
  }

  const [after, setAfter] = useState<string>(null);
  const [loadControlsHistory, { data }] = useLazyQuery(GET_PAGED_CONTROLS, {
    variables: {
      first: 30,
      after,
      filter: {
        type: {
          equalTo: ControlTypes.Rpc,
        },
        objectId: {
          equalTo: defineId(),
        },
        createdAt: {
          greaterThanOrEqualTo: recordedFilter[0],
          lessThanOrEqualTo: recordedFilter[1],
        },
        ...(historyControlName
          ? {
              name: {
                equalTo: historyControlName,
              },
            }
          : null),
      },
      orderBy: ControlExecutionsOrderBy.CreatedAtDesc,
    },
    fetchPolicy: 'cache-and-network',
  });

  const endMessage = buttonCondition && !defineId() ? 'Source is not assigned' : 'No more data';

  const downloadHistory = async (range: (number | Date)[], ids: string[]) => {
    const obj: ControlHistoryData = {
      objects: ids,
      from: format(range[0], 'yyyy-MM-dd 00:00'),
      to: format(range[1], 'yyyy-MM-dd 23:59'),
      rpc_names: historyControlName ? [historyControlName] : undefined,
    };

    const res = await getControlsHistory(obj);
    downloadBlob(res.data, `PixelBoard_ControlsHistory_${obj.from}-${obj.to}.csv`, res.data.type);
  };

  useEffect(() => {
    loadControlsHistory().catch(() => {});
  }, [id, recordedFilter]);

  const fetchData = () => {
    if (data.controlExecutionsConnection.pageInfo.hasNextPage) {
      setAfter(data.controlExecutionsConnection.pageInfo.endCursor as string);
    }
    return null;
  };

  const pageInfo = (datas: GetPagedControlsQuery): PageInfo => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return datas?.controlExecutionsConnection?.pageInfo ?? null;
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
      }}
      className={st.root}
    >
      <HistoryControlsToolbar
        name={object.name}
        onClose={onClose}
        setRecordedFilter={setRecordedFilter}
        setAfter={setAfter}
        downloadHistory={downloadHistory}
        type="controls"
        downloadIds={[defineId()]}
      />
      <div className={st.content} id="scrollContainer">
        <List>
          <InfiniteScroll
            scrollableTarget="scrollContainer"
            dataLength={data?.controlExecutionsConnection?.edges?.length || 0}
            next={fetchData}
            hasMore={pageInfo(data) ? pageInfo(data).hasNextPage : true}
            loader={
              <ListItem className={st.moreMsgContainer}>
                <CircularProgress size={24} />
              </ListItem>
            }
            endMessage={
              <Typography variant={'body1'} className={st.empty}>
                <span>{endMessage}</span>
              </Typography>
            }
          >
            {data?.controlExecutionsConnection.edges.map((item) => (
              <HistoryControlsItem controlExecutionsEdge={item as ControlExecutionsEdge} key={item.node.id} />
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </Box>
  );
};

export const HistoryControlsLayout = create(HistoryControls, { scope: HISTORY_SLOT_ID });
