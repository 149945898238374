import { gql } from '@src/__generated__';

export const GET_OBJECT_PROPERTY = gql(/* GraphQL */ `
  query getObjectProperty($id: UUID!) {
    objectProperty(id: $id) {
      id
      spec {
        id
        description
        units
      }
      property
      object {
        id
        name
        schemaTags
        objectProperties {
          id
          property
          value
        }
      }
    }
  }
`);
