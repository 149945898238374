import CommonModal from '@components/CommonModal';
import Grid from '@mui/material/Grid';
import { ObjectProperty } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import Background from './components/Background';
import Buttons from './components/Buttons';
import Description from './components/Description';
import Format from './components/Format';
import Name from './components/Name';
import Size from './components/Size';
import useForm from './hooks/useForm';

export interface IImageBoxWidgetModalProps {
  onResolve?: () => void;
  isOpen?: boolean;
  widget?: { id: string };
  isEdit?: boolean;
  group?: {
    id: string;
    type: ObjectProperty;
    objectProperties: ObjectProperty[];
  };
  name?: string;
}

const ImageBoxWidgetModal = ({ onResolve, isOpen, widget, isEdit, group, name }: IImageBoxWidgetModalProps) => {
  const { formik, isLoading } = useForm({ onResolve, widget, isEdit, group, name });

  return (
    <CommonModal
      key="EditImagebox"
      modalOpen={isOpen}
      title={isEdit ? 'Edit ImageBox' : 'Add ImageBox'}
      handleClose={onResolve}
      buttons={<Buttons isEdit={isEdit} handleClose={onResolve} />}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="name" placeholder="name" component={Name} />
        <FastField name="format" placeholder="format" component={Format} />
        <FastField name="background" placeholder="background" component={Background} />
        <FastField name="size" placeholder="size" component={Size} />
        <FastField name="description" placeholder="description" component={Description} />
      </Grid>
    </CommonModal>
  );
};
export default create(ImageBoxWidgetModal);
