import { useLazyQuery } from '@apollo/client';
import { HISTORY_SLOT_ID } from '@constants/constants';
import OBJECT_HISTORY_LIST from '@modules/objects/api/objectHistory';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { GET_OBJECT_CORE } from '@shared/api/getObjectCore';
import { Object as ObjectType, ObjectPropertiesHistoriesEdge } from '@src/__generated__/graphql';
import { formatISO, sub } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { create, InstanceProps } from 'react-modal-promise';
import { ComponentItem } from './components/ComponentItem';
import HistoryLoader from './components/HistoryLoader';
import HistoryToolbar from './components/HistoryToolbar';
import { ProcessItemType } from './constants';
import st from './style.module.css';
import { downloadPropertiesHistory, downloadPropertyHistory, processItem } from './utils';

interface IProps {
  propertyId?: string;
  object: ObjectType;
}

const HistoryCommon: FC<IProps & InstanceProps<{}>> = ({
  propertyId: historyPropertyId,
  object,
  onResolve: onClose,
}) => {
  const isPropertyHistory = Boolean(historyPropertyId);

  const [recordedFilter, setRecordedFilter] = useState<[string, string]>([
    formatISO(sub(Date.now(), { days: 1 })),
    formatISO(Date.now()),
  ]);
  const [after, setAfter] = useState(null);
  const [historyItems, setHistoryItems] = useState<ProcessItemType[]>([]);

  const [loadObject] = useLazyQuery(GET_OBJECT_CORE, {
    variables: { objectId: object.id },
    fetchPolicy: 'cache-first',
  });

  const [loadHistory, { data }] = useLazyQuery(OBJECT_HISTORY_LIST, {
    variables: {
      first: 30,
      after,
      filter: {
        objectId: { equalTo: object.id },
        recordedAt: {
          greaterThanOrEqualTo: recordedFilter[0],
          lessThanOrEqualTo: recordedFilter[1],
        },
        ...(isPropertyHistory
          ? {
              propertyId: { equalTo: historyPropertyId },
            }
          : null),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    Promise.all([loadObject(), loadHistory()])
      .then((result) => {
        const [objectRes, historyRes] = result;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const objectPropertiesMap = objectRes.data.object?.objectProperties.reduce((acc, property) => {
          if (!acc[property.id]) {
            acc[property.id as string] = property;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return acc;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as Record<string, any>);
        const items: ProcessItemType[] = (historyRes.data?.objectPropertiesHistoriesConnection?.edges ?? []).map(
          (item) => {
            return processItem(item as ObjectPropertiesHistoriesEdge, objectPropertiesMap);
          }
        );
        setHistoryItems(items);
      })
      .catch(() => {});
  }, [historyPropertyId, recordedFilter, object.id]);

  const fetchData = () => {
    if (data.objectPropertiesHistoriesConnection.pageInfo.hasNextPage) {
      setAfter(data.objectPropertiesHistoriesConnection.pageInfo.endCursor);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
      }}
      className={st.root}
    >
      <HistoryToolbar
        name={object.name}
        onClose={onClose}
        recordedFilter={recordedFilter}
        setRecordedFilter={setRecordedFilter}
        setAfter={setAfter}
        downloadHistory={isPropertyHistory ? downloadPropertyHistory : downloadPropertiesHistory}
        downloadIds={isPropertyHistory ? [historyPropertyId] : [object?.id]}
      />
      <div className={st.content} id="scrollContainer">
        <List>
          <InfiniteScroll
            scrollableTarget="scrollContainer"
            dataLength={historyItems.length}
            next={fetchData}
            hasMore={data?.objectPropertiesHistoriesConnection?.pageInfo?.hasNextPage ?? true}
            loader={<HistoryLoader />}
            endMessage={
              <Typography variant={'body1'} className={st.empty}>
                {historyItems.length === 0 && <span>No more data</span>}
              </Typography>
            }
          >
            {historyItems.map((historyItem, index: number) => (
              <ComponentItem key={index} item={historyItem} />
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </Box>
  );
};

export const HistoryCommonLayout = create(HistoryCommon, { scope: HISTORY_SLOT_ID, enterTimeout: 0 });
