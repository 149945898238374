import { gql } from '@src/__generated__';

const UPDATE_WIDGET = gql(/* GraphQL */ `
  mutation UpdateObj($widgetId: UUID!, $values: [PropertyByKeyInput]) {
    updateObjectWithProperties(input: { detailedObject: [{ objectId: $widgetId, keyedProperties: $values }] }) {
      clientMutationId
    }
  }
`);

export { UPDATE_WIDGET };
