import { useQuery } from '@apollo/client';
import imageboxDefault from '@assets/imageboxDefault.svg';
import ImageSlider from '@components/common/ImageSlider';
import { highlightSelectedStyle } from '@constants/constants';
import { WidgetProps } from '@modules/reports/components/WidgetWrap';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/system';
import { GET_OBJECT_WITH_MEDIA } from '@shared/api/getObjectWithMedia';
import { RootState } from '@src/store';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import useMedia from '@utils/useMedia';
import orderBy from 'lodash.orderby';
import { Image } from 'mui-image';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import WidgetEditControls from '../../WidgetEditControls';
import st from './style.module.css';

// eslint-disable-next-line @typescript-eslint/naming-convention
const W_ImageBox = (props: WidgetProps) => {
  const { selected, object, widgetType, groupId, group } = props;
  const { objectProperties, id, name } = object;

  const theme = useTheme();

  const objectPropertiesMap = useMemo(() => getPropertiesMap(objectProperties), [objectProperties]); // TODO: replace to parent component when all widgets will be written in TypeScript

  const { loading, data: objectWithMedia } = useQuery(GET_OBJECT_WITH_MEDIA, {
    variables: {
      objectId: id,
    },
    fetchPolicy: 'cache-first',
  });

  const { getImageById } = useMedia();

  const isEditMode = useSelector((state: RootState) => state.settings.isEditMode);

  const getImageURL = (img: string | Blob | null) => {
    if (img instanceof Blob) {
      return URL.createObjectURL(img);
    }
    if (typeof img === 'string') {
      return getImageById(img);
    }
    return imageboxDefault;
  };

  const settingsFormat = objectPropertiesMap['settingsFormat']?.value;
  const settingsBackground: string =
    objectPropertiesMap['settingsBackground']?.value === 'light' ? theme.palette.wWhite : theme.palette.wTransparent;

  const images = useMemo(
    () =>
      orderBy(objectWithMedia?.object?.objectsToObjectsByObject1Id, ['object2.name'], ['asc'])
        .filter((obj) => obj.object2?.schemaType === 'media')
        .map((obj) => getImageURL(obj.object2.id as string)),
    [objectWithMedia]
  );

  return (
    <div
      className={st.wrapper}
      style={{
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
        backgroundColor: settingsBackground,
      }}
    >
      {loading && <CircularProgress size={23} />}
      {!loading && images.length > 0 && (
        <ImageSlider backgroundColor={settingsBackground} format={settingsFormat} images={images} />
      )}
      {!loading && images.length === 0 && (
        <Image
          className={st.imageBox}
          duration={300}
          bgColor="#f3f3f3"
          showLoading={loading}
          errorIcon={true}
          src={imageboxDefault}
        />
      )}
      <WidgetEditControls id={id} object={object} name={name} group={group} groupId={groupId} widgetType={widgetType} />
    </div>
  );
};

export default W_ImageBox;
