import { gql } from '@src/__generated__';

export const GET_OBJECT_CORE = gql(/* GraphQL */ `
  query getObjectCore($objectId: UUID!) {
    object(id: $objectId) {
      id
      name
      description
      schema {
        id
        name
        mPicture
        mIcon
      }
      schemaTags
      schemaType
      enabled
      muted
      ...AccessGroupsFragment
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
        groupName
        property
        value
        key
        id
        linkedPropertyId
        updatedAt
        spec {
          id
          hidden
          units
          key
          valueSet
          valueRange
          description
          defaultValue
          type {
            id
            name
          }
        }
        userByBy {
          id
          login
        }
      }
    }
  }
`);
