import { useLazyQuery, useMutation } from '@apollo/client';
import { msg } from '@constants/messages';
import { ObjectProperty } from '@src/__generated__/graphql';
import { getPropertyByKey } from '@utils/getPropertyByKey';
import { widgetSizes } from '@utils/widgetSizes';
import { loader } from 'graphql.macro';
import { toast } from 'react-hot-toast';
import { dispatch as dispatchBus } from 'use-bus';
import useRoute from '../../hooks/useRoute';
import useCustomNavigate from '../hooks/useCustomNavigate';

const CREATE_WIDGET = loader('../../graphql/CreateWidgetMutation.graphql');
const UPDATE_PROPERTY = loader('../../graphql/UpdatePropertyMutation.graphql');
const UPDATE_GROUP_MUTATION = loader('../../graphql/UpdateGroupMutation.graphql');
const DASHBOARD_QUERY = loader('../../graphql/DashboardQuery.graphql');

type TSizeWidget = {
  minW: number;
  minH: number;
  maxH: number;
  w: number;
  h: number;
};

type TProps = {
  group: {
    id: string;
    type?: ObjectProperty;
    objectProperties: ObjectProperty[];
  };
  widgetType: string;
  cb: () => void;
};
export const useAddWidget = ({ group, widgetType }: TProps) => {
  const [createWidget, { loading: isAddingWidget }] = useMutation(CREATE_WIDGET);
  const [updatePropertyLayout, { loading: isUpdatingLayout }] = useMutation(UPDATE_PROPERTY);
  const [updateGroup, { loading: isEditingGroup }] = useMutation(UPDATE_GROUP_MUTATION);

  const { isBoard, isReport, entityId } = useRoute();
  const navigate = useCustomNavigate();

  const [loadEntity] = useLazyQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId(),
    },
    fetchPolicy: 'network-only',
  });

  const targetGroupLayout = getPropertyByKey(group.objectProperties, 'generalLayouts');
  const groupType = group?.type?.[0]?.value || '';

  const getSizeOfWidget = (values) => {
    return widgetSizes(values)[widgetType](groupType) as TSizeWidget;
  };

  const updateWidget = ({ values, defaultValues, id, name, description, cb = () => {} }) => {
    const properties = [];

    for (const prop in values) {
      if (values[prop] !== defaultValues[prop]) {
        properties.push({
          propertyKey: prop,
          value: values[prop],
        });
      }
    }

    toast
      .promise(
        updateGroup({
          variables: {
            id,
            name,
            description,
            values: properties,
          },
        }),
        {
          loading: 'Updating widget...',
          success: () => msg.editWidgetModal.updated,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        if ('settingsSize' in defaultValues && defaultValues.settingsSize !== values.settingsSize) {
          return updatePropertyLayout({
            variables: {
              input: {
                id: targetGroupLayout.id,
                patch: {
                  value: [
                    ...targetGroupLayout.value.filter((i) => i.i !== id),
                    {
                      ...targetGroupLayout.value.find((i) => i.i === id),
                      ...getSizeOfWidget(values),
                    },
                  ],
                },
              },
            },
          });
        } else {
          cb();
        }
      })
      .then(() => {
        dispatchBus('@@board/RESET_DASHBOARD_LOCAL_STATE');
        cb();
      })
      .catch(() => {});
  };

  const createWidgetFn = ({ values, name, description, cb }) => {
    const computePositionForNewWidget = (id) => {
      const widget = {
        i: id,
        x: 0,
        y: 0,
        ...getSizeOfWidget(values),
      };

      if (!targetGroupLayout?.value?.length) return widget;

      return {
        ...widget,
        y: 0,
        x: 0,
      };
    };

    const reCalculateLayoutGroup = (newWidgetId) => {
      const newWidget = computePositionForNewWidget(newWidgetId);

      if (!targetGroupLayout?.value?.length) {
        return [newWidget];
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return [...targetGroupLayout.value, newWidget];
      }
    };

    createWidget({
      variables: {
        groupId: group.id,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        values: Object.keys(values).map((key) => {
          return { propertyKey: key, value: values[key] };
        }),
        widgetType,
        name,
        description,
      },
    })
      .then(({ data }) => {
        const newLayout = reCalculateLayoutGroup(data.createObjectWithProperties.uuid);
        return Promise.all([
          updatePropertyLayout({
            variables: {
              input: {
                id: targetGroupLayout.id,
                patch: {
                  value: newLayout,
                },
              },
            },
          }),
          Promise.resolve(data.createObjectWithProperties.uuid),
        ]);
      })
      .then(async ([_, id]) => {
        if (targetGroupLayout.value.length === 0) {
          await loadEntity();
        }

        if (isReport()) {
          navigate(`/reports/${entityId()}/${group.id}/${id}`);
        }

        if (isBoard()) {
          navigate(`/boards/${entityId()}/${group.id}/${id}`);
        }

        cb();
      })
      .catch((e: any): void => {
        toast.error((e?.message as string) || 'Failed while creating widget');
      });
  };

  return {
    createWidgetFn,
    updateWidget,
    isLoading: isEditingGroup || isAddingWidget || isUpdatingLayout,
  };
};
