import { CommonFieldProps } from '@components/create-widget/DataChartColor/constants';
import CustomInput from '@components/CustomInput';
import { Grid } from '@mui/material';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../constants';

export const MessageInput = ({
  form,
  field,
  label,
  clearFieldIcon,
  type,
  multiline,
}: FieldProps<IFormValues> & CommonFieldProps) => {
  return (
    <Grid item>
      <CustomInput
        {...fieldRequiredProps(form, validationSchema, field.name)}
        label={label}
        type={type}
        multiline={multiline}
        clearFieldIcon={clearFieldIcon}
      />
    </Grid>
  );
};
