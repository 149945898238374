import { FormatOptionsEnum } from '@modules/widgets/forms/ImageBoxWidgetModal/constants/formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';

import st from './style.module.css';

interface ImageSliderProps {
  images: string[];
  backgroundColor: string;
  format: FormatOptionsEnum;
}

const ImageSlider = ({ images, backgroundColor, format }: ImageSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
  }, [images]);

  const totalImages = images.length;

  const handleNext = () => {
    if (currentIndex < totalImages - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const isSingleImage = totalImages === 1;

  return (
    <Box
      className={st.sliderContainer}
      sx={{
        backgroundColor,
      }}
    >
      <Box
        className={st.imageContainer}
        sx={{
          transform: `translateX(-${currentIndex * 100}%)`,
          width: `${totalImages * 100}%`,
        }}
      >
        {images.map((image, index) => (
          <Box
            className={st.imageBox}
            key={image}
            sx={{
              backgroundColor,
            }}
          >
            <Box
              component="img"
              src={image}
              alt={`Slide ${index + 1}`}
              sx={{
                display: 'block',
                width: '100%',
                height: '100%',
                margin: 'auto',
                maxWidth: format === FormatOptionsEnum.savingscale ? '100%' : 'auto',
                maxHeight: format === FormatOptionsEnum.savingscale ? '100%' : 'auto',
                objectFit: format === FormatOptionsEnum.savingscale ? 'contain' : 'cover',
              }}
            />
          </Box>
        ))}
      </Box>

      {!isSingleImage && (
        <IconButton
          size={'small'}
          onClick={handlePrev}
          disabled={isSingleImage || currentIndex === 0}
          className={st.buttonPrev}
        >
          <ArrowBackIosNewIcon fontSize={'inherit'} />
        </IconButton>
      )}

      {!isSingleImage && (
        <IconButton
          size={'medium'}
          onClick={handleNext}
          disabled={isSingleImage || currentIndex === totalImages - 1}
          className={st.buttonNext}
        >
          <ArrowForwardIosIcon fontSize={'inherit'} />
        </IconButton>
      )}
    </Box>
  );
};

export default ImageSlider;
