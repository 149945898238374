import { useLazyQuery } from '@apollo/client';
import ControlsHistoryInfoModal from '@components/modals/ControlsHistoryInfoModal';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { ControlExecution, ControlExecutionsEdge } from '@src/__generated__/graphql';
import isJsonString from '@utils/isJsonString';
import { format } from 'date-fns';

import { GET_CONTROL_INFO } from '../../api/getControlInfo';
import { ControlStatusIcon } from './components/ControlStatusIcon';
import st from './style.module.css';

const listItemClasses = {
  container: st.itemToHover,
};

type HistoryControlsItemProps = {
  controlExecutionsEdge: ControlExecutionsEdge;
};

const HistoryControlsItem = (props: HistoryControlsItemProps) => {
  const { node } = props.controlExecutionsEdge;

  const [getReportsForControlExecution, { loading }] = useLazyQuery(GET_CONTROL_INFO, {
    variables: {
      linkedControlId: node.id,
    },
    fetchPolicy: 'cache-first',
  });

  const listArguments = (params: any) => {
    let paramsInner = params;
    if (typeof params === 'string') {
      paramsInner = JSON.parse(params);
    }
    const paramsTransformed = [];
    const isArray = Array.isArray(paramsInner);
    if (isArray) {
      return paramsInner;
    } else {
      for (const prop in paramsInner) {
        paramsTransformed.push({
          argument: prop,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          isJson: isJsonString(paramsInner[prop]),
          value: paramsInner[prop],
        });
      }
    }
    return paramsTransformed;
  };
  const handleInfo = () => {
    const params = listArguments(node?.params);
    getReportsForControlExecution()
      .then((res) => {
        if (res.data.controlExecutions.length === 0) {
          ControlsHistoryInfoModal({
            property: {
              createdAt: node.createdAt,
            } as ControlExecution,
            title: node.name,
            params,
            hasReports: false,
          }).catch(() => {});
        } else {
          const [property] = res.data.controlExecutions;
          ControlsHistoryInfoModal({
            property: property as ControlExecution,
            title: node.name,
            params,
            hasReports: true,
          }).catch(() => {});
        }
      })
      .catch(() => {});
  };

  return (
    <ListItem classes={listItemClasses} data-testid={String(node.id)}>
      <IconButton disabled>
        <ControlStatusIcon execution={node} />
      </IconButton>
      <ListItemText
        primary={<Typography noWrap>{node.name + '()'}</Typography>}
        secondary={
          `${format(new Date(node.createdAt as string | number | Date), 'MMM d, hh:mm:ss a')}` +
          ' by ' +
          node.caller.mName
        }
      />
      <ListItemSecondaryAction className={st.itemToHideOrShow}>
        <IconButton disabled={loading} edge="end" onClick={handleInfo} size="large">
          <InfoIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default HistoryControlsItem;
