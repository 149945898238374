import { useQuery } from '@apollo/client';
import { useAddWidget } from '@components/create-widget/useAddWidget';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import useRoute from '@hooks/useRoute';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { ICommandButtonWidgetModalProps } from '@modules/widgets/forms/CommandButtonWidgetModal';
import { ObjectProperty } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { useMemo } from 'react';
import {
  BACKGROUND_OPTIONS,
  FORMAT_OPTIONS,
  IFormValues,
  SETTINGS_SIZE_OPTIONS,
  validationSchema,
} from '../constants/formik';

const useForm = ({ onResolve, widget, isEdit, group, name: nameProps }: ICommandButtonWidgetModalProps) => {
  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group,
    widgetType: WIDGETS_ENUM.IMAGEBOX,
    cb: () => {},
  });

  const { data, loading: loadingWidgets } = useQuery(GET_WIDGET, {
    variables: { objId: widget?.id },
    fetchPolicy: 'cache-first',
  });

  const objectPropertiesMap = useMemo(
    () => getPropertiesMap(data?.object?.objectProperties as ObjectProperty[]),
    [data?.object?.objectProperties]
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const getPropValue = (prop: string) => objectPropertiesMap?.[prop]?.value;
  const navigate = useCustomNavigate();
  const { entityId } = useRoute();

  const initialValues = {
    name: isEdit ? data?.object?.name : nameProps,
    format: isEdit ? getPropValue('settingsFormat') : FORMAT_OPTIONS[0].value,
    size: isEdit ? getPropValue('settingsSize') : SETTINGS_SIZE_OPTIONS[0].value,
    description: isEdit ? data?.object?.description : '',
    background: isEdit ? getPropValue('settingsBackground') : BACKGROUND_OPTIONS[0].value,
  };

  const formik = {
    initialValues,
    validationSchema,
    onSubmit: ({ format, size, background, name, description }: IFormValues) => {
      {
        if (isEdit) {
          updateWidget({
            values: {
              settingsFormat: format,
              settingsBackground: background,
              settingsSize: size,
            },
            defaultValues: {
              settingsFormat: initialValues?.format,
              settingsBackground: initialValues?.background,
              settingsSize: initialValues?.size,
            },
            id: widget?.id,
            name,
            description,
            cb: () => {
              onResolve();
            },
          });
        } else {
          createWidgetFn({
            values: {
              settingsFormat: format,
              settingsBackground: background,
              settingsSize: size,
            },
            name,
            description,
            cb: (e) => {
              const id = e?.data?.createObjectWithProperties?.uuid as string;
              if (id) navigate(`/boards/${entityId()}/${group?.id}/${id}`);
              onResolve();
            },
          });
        }
      }
    },
  };

  return { formik: isEdit ? data && formik : formik, isLoading: isLoading || loadingWidgets };
};

export default useForm;
