import { useLazyQuery, useMutation } from '@apollo/client';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import ConditionsItem from '@components/side-card/object-statistic/TabGeneralObjectStatistic/components/ConditionsItem';
import { GET_SCHEMA } from '@modules/objects/api/getSchema';
import { UPDATE_PROPERTY } from '@modules/objects/api/updateProperty';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { useEffect, useMemo } from 'react';

import FunctionItem from './components/FunctionItem';
import LinkingItem from './components/LinkingItem';
import PropertyItem from './components/PropertyItem';
import SchemaItem from './components/SchemaItem';
import ValueItem from './components/ValueItem';
import st from './style.module.css';

export const containerClasses = {
  container: st.itemToHover,
};

interface IProps {
  item: ObjectType;
}

const TabGeneralObjectStatistic = ({ item }: IProps) => {
  const objectPropertiesMap = useMemo(() => getPropertiesMap(item.objectProperties), [item.objectProperties]);

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [loadSchema, { data: schemaData }] = useLazyQuery(GET_SCHEMA, {
    variables: {
      id: objectPropertiesMap?.settingsSchemaid?.value,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleUpdateProperty = async (id: string, value) => {
    if (id) {
      await updateProperty({
        variables: {
          input: {
            id,
            patch: {
              value,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (objectPropertiesMap?.settingsSchemaid?.value) {
      loadSchema().then(() => {});
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={st.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ValueItem item={item} value={objectPropertiesMap.valueValue?.value} id={objectPropertiesMap?.valueValue?.id} />

        <ListSubheader color="primary" className={st.listSubheader}>
          <Typography variant="subtitle2">Settings</Typography>
        </ListSubheader>

        <SchemaItem
          value={schemaData?.schema?.name}
          onChange={async (id: string, value: string) => {
            await Promise.all([
              handleUpdateProperty(id, value),
              handleUpdateProperty(objectPropertiesMap?.settingsProperty?.id as string, null),
            ]);
          }}
          item={item}
          settingsSchemaid={objectPropertiesMap?.settingsSchemaid}
        />

        <PropertyItem
          properties={schemaData?.schema?.schemaProperties}
          item={item}
          onChange={handleUpdateProperty}
          settingsProperty={objectPropertiesMap?.settingsProperty}
        />

        <LinkingItem
          item={item}
          onChange={handleUpdateProperty}
          settingsLinkedOnly={objectPropertiesMap?.settingsLinkedOnly}
        />

        <FunctionItem
          item={item}
          onChange={handleUpdateProperty}
          settingsFunction={objectPropertiesMap?.settingsFunction}
        />

        <ListSubheader color="primary" className={st.listSubheader}>
          <Typography variant="subtitle2">Filter</Typography>
        </ListSubheader>

        <ConditionsItem
          schemaProperties={schemaData?.schema?.schemaProperties}
          onChange={handleUpdateProperty}
          settingsFilter={objectPropertiesMap?.settingsFilter}
          disabled={!objectPropertiesMap?.settingsSchemaid.value}
        />

        <ServiceSection classes={st} item={item} />

        <AccessSection classes={st} item={item} />

        {item.description && <DescriptionSection classes={st} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralObjectStatistic;
