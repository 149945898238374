import CommonModal from '@components/CommonModal';
import { msg } from '@constants/messages';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { create } from 'react-modal-promise';
import IconWindow from './components/IconWindow';
import ListItem from './components/ListItem';
import useAboutModalData from './hooks/useAboutModalData';

type AboutModalProps = {
  onResolve: () => void;
  isOpen: boolean;
};

const AboutModal = ({ onResolve: handleClose, isOpen }: AboutModalProps) => {
  const { loading, error, data, aboutInfo } = useAboutModalData();

  return (
    <CommonModal
      modalOpen={isOpen}
      loading={loading}
      title={msg.aboutModal.aboutProgram}
      forceTitle={true}
      handleClose={handleClose}
      buttons={
        <Button data-test-about="close" onClick={handleClose}>
          {msg.aboutModal.buttonOK}
        </Button>
      }
    >
      <Grid container direction="column" spacing={2} minHeight={100}>
        {!loading && (
          <>
            <IconWindow />
            {data?.map((props, index) => (
              <ListItem key={`listItem-${index}`} {...props} />
            ))}
            <Grid paddingBlock={'12px'} pl="16px">
              <Box display="flex" justifyContent="space-between" dangerouslySetInnerHTML={{ __html: aboutInfo }}></Box>
            </Grid>
          </>
        )}
        {error && <div>Version query error!</div>}
      </Grid>
    </CommonModal>
  );
};

export default create(AboutModal);
