import { useLazyQuery } from '@apollo/client';
import CustomAutocomplete from '@components/CustomAutocomplete';
import { SEARCH_MEDIA } from '@modules/media/api/SearchMedia';
import { InputProps } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

// TODO: import from CustomAutocomplete
export type OptionValue = { title: string; value: string; disabled?: boolean };
export type SelectedOption = {
  target: { name: string; value: any; rawValue?: OptionValue };
};

export interface IMediaAutocompleteProps {
  name: string;
  value: string | Blob | null;
  onChange: (option: SelectedOption) => void;
  label: string;
  error?: boolean;
  required?: boolean;
  inputProps?: Partial<InputProps>;
}

const autoCompleteFilterCb = (options: OptionValue[], { inputValue }: { inputValue: string }) =>
  options.filter(
    (option) =>
      option.title.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.value.toLowerCase().includes(inputValue.toLowerCase())
  );

const MediaAutocomplete = ({ name, value, onChange, label, error, required, inputProps }: IMediaAutocompleteProps) => {
  const [objects, setObjects] = useState<OptionValue[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>('');

  const [loadMedia, { loading }] = useLazyQuery(SEARCH_MEDIA, {
    onCompleted: ({ objects: resObjects }) => {
      setObjects(resObjects?.map((option) => ({ title: option.title, value: option.value })) || []);
    },
    fetchPolicy: 'cache-first',
  });

  useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    300,
    [inputValue]
  );

  useEffect(() => {
    if (value && !(value instanceof Blob))
      loadMedia({
        variables: {
          query: value,
        },
      }).catch(() => {});
  }, [value]);

  useEffect(() => {
    loadMedia({
      variables: {
        query: debouncedValue,
      },
    }).catch(() => {});
  }, [debouncedValue]);

  const handleInputChange = (e: SyntheticEvent<HTMLInputElement>, val: string, reason: string) => {
    if (reason !== 'reset') {
      setInputValue(val?.trim() ?? '');
    }
  };
  const handleSelect = (e: SelectedOption) => {
    onChange(e);
  };

  return (
    <CustomAutocomplete
      required={required}
      filterOptions={autoCompleteFilterCb}
      clearFieldIcon
      error={error}
      name={name}
      label={label}
      list={objects}
      value={value}
      loading={loading}
      onChange={handleSelect}
      inputProps={inputProps}
      onInputChange={handleInputChange}
    />
  );
};

export default MediaAutocomplete;
