import defaultUploadImg from '@assets/upload.svg';
import IdentifierIcon from '@components/icons/IdentifierIcon';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LinkOff from '@mui/icons-material/LinkOff';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useMedia from '@utils/useMedia';
import { Image } from 'mui-image';
import { ChangeEventHandler, useRef, useState } from 'react';

import { ACCEPT_FORMATS, MAX_SIZE, ViewTypes } from '../../constants';
import { SelectedOption } from '../MediaAutocomplete';
import st from './style.module.css';

type IProps = {
  value: string | Blob | null;
  withUnlink?: boolean;
  withSearch?: boolean;
  label?: string;
  onDelete?: () => void;
  setCurrentView?: (newState: ViewTypes) => void;
  onChange?: (e: SelectedOption) => void;
  name: string;
};

const ImageBox = ({ value, setCurrentView, onDelete, withUnlink = false, withSearch, onChange, name }: IProps) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const { getImageById } = useMedia();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleCopy = useHandleCopy();

  const handleDelete = () => {
    if (value) {
      onDelete?.();
      onChange({
        target: {
          name,
          value: null,
        },
      });
    } else {
      if (formRef.current) {
        onChange(null);
      }
    }
  };

  const handleUnlink = () => {
    if (withUnlink) {
      onChange({
        target: {
          name,
          value: null,
        },
      });
    }
  };

  const pickFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    setErrorMessage('');
    const file: Blob | MediaSource = e.target.files[0];
    if (file.size > MAX_SIZE) {
      setErrorMessage(`File too large (max ${MAX_SIZE} bytes)`);
      return false;
    }
    if (!ACCEPT_FORMATS.includes(file.type)) {
      setErrorMessage('Wrong file format');
      return false;
    }

    onChange({
      target: {
        name,
        value: file,
      },
    });
  };

  const getImageURL = (img: IProps['value']) => {
    if (img instanceof Blob) {
      return URL.createObjectURL(img);
    }
    if (typeof img === 'string') {
      return getImageById(img);
    }
    return defaultUploadImg;
  };

  return (
    <div className={st.formContainer} ref={formRef}>
      <div>
        <Image
          className={st.imageBox}
          duration={300}
          bgColor="#f3f3f3"
          showLoading
          errorIcon={true}
          src={getImageURL(value)}
        />
        {errorMessage && <div className={st.errorMessage}>{errorMessage}</div>}
      </div>

      <div className={st.toolsContainer}>
        {withSearch && (
          <Tooltip title={'Add image by manually typing UUID of existed media'} disableTouchListener>
            <IconButton
              className={st.iconButton}
              onClick={() => {
                setCurrentView(ViewTypes.UUID);
              }}
              size="large"
            >
              <IdentifierIcon />
            </IconButton>
          </Tooltip>
        )}
        <div className={st.toolsContainerRight}>
          {value && (
            <IconButton
              className={st.iconButton}
              onClick={() => {
                handleCopy({
                  object: {
                    name: '',
                  },
                  message: 'Image UID copied successfully',
                  text: typeof value === 'string' ? value : '',
                });
              }}
              size="large"
            >
              <FilterNoneIcon />
            </IconButton>
          )}

          {!value && (
            <IconButton
              className={st.iconButton}
              onClick={() => {
                if (uploadInputRef.current) {
                  uploadInputRef.current.click();
                }
              }}
              size="large"
            >
              <CloudUploadIcon />
            </IconButton>
          )}

          {value && (
            <Tooltip title={'The image will be permanently deleted after saving'} disableTouchListener>
              <IconButton className={st.iconButton} onClick={handleDelete} size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {withUnlink && value && (
            <Tooltip title={'Unlink the image from current object'} disableTouchListener>
              <IconButton className={st.iconButton} onClick={handleUnlink} size="large">
                <LinkOff />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>

      {!value && (
        <input
          ref={uploadInputRef}
          type="file"
          accept={ACCEPT_FORMATS.join(',')}
          hidden
          multiple={false}
          onChange={pickFile}
        />
      )}
    </div>
  );
};

export default ImageBox;
