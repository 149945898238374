import { gql } from '@src/__generated__';

export const GET_CONTROL_INFO = gql(/* GraphQL */ `
  query getReportsForControlExecution($linkedControlId: BigInt!) {
    controlExecutions(
      orderBy: CREATED_AT_DESC
      filter: { linkedControlId: { equalTo: $linkedControlId }, type: { equalTo: REPORT } }
    ) {
      id
      done
      ack
      linkedControlId
      error
      createdAt
      name
    }
  }
`);
