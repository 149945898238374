import * as yup from 'yup';

export interface IFormValues {
  name: string;
  description: string;
  image: string | Blob | null;
}

export const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
});
