import { useQuery } from '@apollo/client';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import { useAddWidget } from '@components/create-widget/useAddWidget';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import useRoute from '@hooks/useRoute';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { ILineWidgetModalProps } from '@modules/widgets/forms/LineWidgetModal';
import {
  LINE_COLOR_OPTIONS,
  ORIENTATION_OPTIONS,
  WIDTH_OPTIONS,
} from '@modules/widgets/forms/LineWidgetModal/constants/constants';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { IFormValues, validationSchema } from '../constants/formik';

const useForm = ({ onResolve, widget, isEdit, group, name: nameProps }: ILineWidgetModalProps) => {
  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group,
    widgetType: WIDGETS_ENUM.LINE,
    cb: () => {},
  });

  const { uploadImageWithoutLink } = useUpdateBackgroundImage();

  const { data, loading: loadingWidgets } = useQuery(GET_WIDGET, {
    variables: { objId: widget?.id },
    fetchPolicy: 'cache-first',
  });

  const getPropValue = (prop) => data?.object?.objectProperties?.find((obj) => obj.key === prop)?.value;
  const navigate = useCustomNavigate();
  const { entityId } = useRoute();

  const initialValues = {
    name: isEdit ? data?.object?.name : nameProps,
    color: isEdit ? getPropValue('settingsColor') : LINE_COLOR_OPTIONS[0].value,
    leftEnd: isEdit ? getPropValue('settingsLeftEnd') : '',
    rightEnd: isEdit ? getPropValue('settingsRightEnd') : '',
    orientation: isEdit ? getPropValue('settingsOrientation') : ORIENTATION_OPTIONS[0].value,
    width: isEdit ? getPropValue('settingsLineWidth') : WIDTH_OPTIONS[0].value,
    coverImage: isEdit ? getPropValue('settingsCoverImage') : null,
    description: isEdit ? data?.object?.description : '',
  };

  const formik = {
    initialValues,
    validationSchema,
    onSubmit: async ({ color, leftEnd, rightEnd, name, description, orientation, width, coverImage }: IFormValues) => {
      const [coverImageUid] = await Promise.all([
        uploadImageWithoutLink(initialValues.coverImage as string, coverImage),
      ]);
      {
        if (isEdit) {
          void updateWidget({
            values: {
              settingsColor: color,
              settingsLeftEnd: leftEnd,
              settingsRightEnd: rightEnd,
              settingsOrientation: orientation,
              settingsLineWidth: parseInt(width),
              settingsCoverImage: coverImageUid,
            },
            defaultValues: {},
            id: widget?.id,
            name,
            description,
            cb: () => {
              onResolve();
            },
          });
        } else {
          void createWidgetFn({
            values: {
              settingsColor: color,
              settingsLeftEnd: leftEnd,
              settingsRightEnd: rightEnd,
              settingsOrientation: orientation,
              settingsLineWidth: parseInt(width),
              settingsCoverImage: coverImageUid,
            },
            name,
            description,
            cb: (e) => {
              const id = e?.data?.createObjectWithProperties?.uuid as string;
              if (id) navigate(`/boards/${entityId()}/${group?.id}/${id}`);
              onResolve();
            },
          });
        }
      }
    },
  };

  return { formik: isEdit ? data && formik : formik, isLoading: isLoading || loadingWidgets };
};

export default useForm;
