import { WIDGETS_ENUM } from './widgetTypes';

export const widgetSizes = (values) => {
  return {
    [WIDGETS_ENUM.DATABOX]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 2,
            // maxH: 2,
            w: 2,
            h: 2,
          },
          tiny: {
            minW: 2,
            minH: 2,
            // maxH: 2,
            w: 2,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 4,
            // maxH: 4,
            w: 4,
            h: 4,
          },
        },
        minimap: {
          tiny: {
            minW: 2,
            minH: 2,
            // maxH: 2,
            w: 2,
            h: 2,
          },
          small: {
            minW: 4,
            minH: 2 * 2,
            // maxH: 2 * 2,
            w: 4,
            h: 2 * 2,
          },
          medium: {
            minW: 2 * 4,
            minH: 4 * 2,
            // maxH: 4 * 2,
            w: 2 * 4,
            h: 4 * 2,
          },
        },
      };
      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.GEO_TIMER]: () => {
      return {
        minW: 4,
        minH: 2,
        maxH: 2,
        w: 4,
        h: 2,
      };
    },
    [WIDGETS_ENUM.COMMAND_BUTTON]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 2,
            maxH: 2,
            w: 2,
            h: 2,
          },
          tiny: {
            minW: 2,
            minH: 2,
            maxH: 2,
            w: 2,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 4,
            maxH: 4,
            w: 4,
            h: 4,
          },
        },
        minimap: {
          tiny: {
            minW: 2,
            minH: 2,
            maxH: 2,
            w: 2,
            h: 2,
          },
          small: {
            minW: 4,
            minH: 2 * 2,
            maxH: 2 * 2,
            w: 4,
            h: 2 * 2,
          },
          medium: {
            minW: 2 * 4,
            minH: 4 * 2,
            maxH: 4 * 2,
            w: 2 * 4,
            h: 4 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.LINE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 1,
            minH: 1,
            w: 1,
            h: 1,
          },
        },
        minimap: {
          small: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'small'];
    },
    [WIDGETS_ENUM.ADVANCED_BUTTON]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 5,
            minH: 1,
            w: 6,
            h: 4,
          },
          tiny: {
            minW: 5,
            minH: 1,
            w: 6,
            h: 4,
          },
          medium: {
            minW: 5,
            minH: 1,
            w: 6,
            h: 4,
          },
        },
        minimap: {
          small: {
            minW: 6,
            minH: 2,
            w: 6,
            h: 4,
          },
          tiny: {
            minW: 6,
            minH: 2,
            w: 6,
            h: 4,
          },
          medium: {
            minW: 6,
            minH: 2,
            w: 6,
            h: 4,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.IMAGEBOX]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 2,
            w: 2,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 4,
            w: 4,
            h: 4,
          },
          large: {
            minW: 8,
            minH: 8,
            w: 8,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.MONITOR_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.MONITOR_STATUS]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.HISTORY_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.STATIC_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.PROPERTY_HISTORY_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.GEOTAGS_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.TRACKING_TABLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.DATACHART]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.DATACHART_COLOR]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.DIAGRAM]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 2,
          },
          medium: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 4,
          },
          large: {
            minW: 4,
            minH: 2,
            w: 10,
            h: 8,
          },
        },
        minimap: {
          small: {
            minW: 10 * 2,
            minH: 2 * 2,
            w: 10 * 2,
            h: 2 * 2,
          },
          medium: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 4 * 2,
          },
          large: {
            minW: 10 * 2,
            minH: 4 * 2,
            w: 10 * 2,
            h: 8 * 2,
          },
        },
      };

      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.TITLE]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 2,
            minH: 1,
            w: 4,
            h: 2,
          },
        },
        minimap: {
          small: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
        },
      };
      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.DATETIME]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 2,
            minH: 1,
            w: 4,
            h: 2,
          },
        },
        minimap: {
          small: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
          },
        },
      };
      return sizes[type][values.settingsSize || 'medium'];
    },
    [WIDGETS_ENUM.TIMER]: (type) => {
      const sizes = {
        group: {
          small: {
            minW: 2,
            minH: 1,
            maxH: 1,
            w: 2,
            h: 1,
          },
          tiny: {
            minW: 2,
            minH: 1,
            maxH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 4,
            minH: 2,
            maxH: 2,
            w: 4,
            h: 2,
          },
        },
        minimap: {
          small: {
            minW: 2,
            minH: 1,
            maxH: 1,
            w: 2,
            h: 1,
          },
          tiny: {
            minW: 2,
            minH: 1,
            maxH: 1,
            w: 2,
            h: 1,
          },
          medium: {
            minW: 4,
            minH: 2,
            maxH: 2,
            w: 4,
            h: 2,
          },
        },
      };
      return sizes[type][values.settingsSize || 'medium'];
    },
  };
};

export const BASE_HEIGHT = 50;
