import { useSelectWidget } from '@components/create-widget/useSelectWidget';
import DeleteWidgetModal from '@components/DeleteWidgetModal';
import { msg } from '@constants/messages';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Fade } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import { RootState } from '@src/store';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { WidgetTypesEnum } from '@utils/widgetTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import st from './style.module.css';

interface IProps {
  id?: string;
  name?: string;
  group?: any;
  widgetType?: WidgetTypesEnum;
  groupId?: string;
  object?: ObjectType;
}

const WidgetEditControls = ({ id, name, group, groupId: propsGroupId, object, widgetType }: IProps) => {
  const { groupId, widgetId } = useParams();

  const { selectByType } = useSelectWidget({ group });

  const theme = useTheme();
  const isEditMode = useSelector((state: RootState) => state.settings.isEditMode);

  const checkIsMayShow = () => {
    if (!isEditMode || !groupId) return false;

    if (groupId !== propsGroupId) return false;

    return !widgetId || object.id === widgetId;
  };

  return (
    <Fade in={checkIsMayShow()}>
      <div className={st.wrapper} style={{ zIndex: (theme.zIndex as Record<string, number>)?.drawer + 1 }}>
        <Grid container justifyContent="center">
          <Grid item style={{ whiteSpace: 'nowrap' }}>
            <Tooltip title={object.name}>
              <IconButton
                data-test-widget-control-edit={object.name}
                className={st.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={() => {
                  selectByType({
                    widgetType,
                    name: object.name,
                    widget: object,
                    group,
                  });
                }}
                size="large"
              >
                <EditIcon fontSize="small" style={{ color: theme.palette.white }} />
              </IconButton>
            </Tooltip>

            <Tooltip title={msg.widgetEdit.delete}>
              <IconButton
                data-test-widget-control-delete={object.name}
                className={st.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={() => {
                  DeleteWidgetModal({
                    widgetId: id,
                    groupId,
                    name,
                  })
                    .then()
                    .catch(() => {});
                }}
                size="large"
              >
                <DeleteIcon fontSize="small" style={{ color: theme.palette.white }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default React.memo(WidgetEditControls);
