import DataChartColor from '@components/create-widget/DataChartColor';
import AdvancedButtonWidgetModal, {
  IAdvancedButtonWidgetModalProps,
} from '@modules/widgets/forms/AdvancedButtonWidgetModal';
import ImageBoxWidgetModal, { IImageBoxWidgetModalProps } from '@modules/widgets/forms/ImageBoxWidgetModal';
import LineWidgetModal, { ILineWidgetModalProps } from '@modules/widgets/forms/LineWidgetModal';
import { WidgetTypesEnum } from '@utils/widgetTypes';
import CommandButtonWidgetModal, {
  ICommandButtonWidgetModalProps,
} from 'modules/widgets/forms/CommandButtonWidgetModal';
import AddWidgetModal from '../AddWidgetModal';
import EditWidgetModal from '../EditWidgetModal';
import CreateDatabox from './databox/CreateDatabox';
import CreateGeotagsTable from './geotags-table/CreateGeotagsTable';
import CreatePropertyHistoryTable from './property-history-table/CreatePropertyHistoryTable';
import RegularChart from './regular-chart/RegularChart';
import CreateStaticTable from './static-table/CreateStaticTable';
import CreateTrackingTable from './tracking-table/CreateTrackingTable';
import { TDefaultWidgetProps } from './types';

const widgetMap = {
  [WidgetTypesEnum.DATACHART]: (props: TDefaultWidgetProps) => RegularChart(props),
  [WidgetTypesEnum.TRACKING_TABLE]: (props: TDefaultWidgetProps) => CreateTrackingTable(props),
  [WidgetTypesEnum.STATIC_TABLE]: (props: TDefaultWidgetProps) => CreateStaticTable(props),
  [WidgetTypesEnum.GEOTAGS_TABLE]: (props: TDefaultWidgetProps) => CreateGeotagsTable(props),
  [WidgetTypesEnum.DATABOX]: (props: TDefaultWidgetProps) => CreateDatabox(props),
  [WidgetTypesEnum.PROPERTY_HISTORY_TABLE]: (props: TDefaultWidgetProps) => CreatePropertyHistoryTable(props),
  [WidgetTypesEnum.DATACHART_COLOR]: (props: TDefaultWidgetProps) => DataChartColor(props),
  [WidgetTypesEnum.COMMAND_BUTTON]: (props: ICommandButtonWidgetModalProps) => CommandButtonWidgetModal(props),
  [WidgetTypesEnum.ADVANCED_BUTTON]: (props: IAdvancedButtonWidgetModalProps) => AdvancedButtonWidgetModal(props),
  [WidgetTypesEnum.LINE]: (props: ILineWidgetModalProps) => LineWidgetModal(props),
  [WidgetTypesEnum.IMAGEBOX]: (props: IImageBoxWidgetModalProps) => ImageBoxWidgetModal(props),
};

type TSelectWidgetProps = {
  group: any;
};

type TSelectByTypeParams = {
  widgetType: WidgetTypesEnum;
  name: string;
  cb?: () => void;
  widget?: any;
  group?: any;
};

export const useSelectWidget = (props: TSelectWidgetProps) => {
  const selectByType = (params: TSelectByTypeParams) => {
    const isEdit = Boolean(params?.widget);
    if (params.widgetType in widgetMap) {
      widgetMap[params.widgetType]({
        name: params.name,
        widget: params.widget,
        group: props.group,
        widgetType: params.widgetType,
        isEdit,
      })
        .then(() => {
          params?.cb?.();
        })
        .catch(() => {});
    } else if (isEdit) {
      EditWidgetModal({
        object: params.widget,
      })
        .then()
        .catch(() => {});
    } else {
      AddWidgetModal({
        group: props.group,
        name: params.name,
        groupId: props.group.id,
        widgetType: params.widgetType,
      })
        .then(() => {
          params?.cb();
        })
        .catch(() => {});
    }
  };

  return {
    selectByType,
  };
};
