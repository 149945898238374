import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, SETTINGS_SIZE_OPTIONS, validationSchema } from '../../constants/formik';

const Size: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <>
      <Grid item>
        <CustomSelect
          {...fieldRequiredProps(form, validationSchema, 'size')}
          label="Size"
          list={SETTINGS_SIZE_OPTIONS}
        />
      </Grid>
    </>
  );
};

export default Size;
