import FunctionIcon from '@components/icons/functionIcon';
import PercentIcon from '@components/icons/percentIcon';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import tabStyle from '@components/side-card/tab.module.css';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

const classes = { container: tabStyle.itemToHover };

const TabGeneralCounterStatistic = (props) => {
  const { item } = props;

  const handleCopy = useHandleCopy();

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const getValue = (value) => {
    if (value === null) return 'n/a';
    return value;
  };

  const valueProperty = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  const valuePercentageProperty = () => {
    return item.objectProperties.find((item) => item.key === 'valuePercentage');
  };

  return (
    <>
      <ValueMenu
        items={[
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              HistoryCommonLayout({
                object: item,
                propertyId: obj.propId,
              }).catch(() => {});
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: JSON.stringify(obj.propValue),
                message: 'Value copied',
              });
            },
            disabled: false,
          },
        ]}
      />

      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ListItem style={{ height: '48px' }} classes={classes}>
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleCopy({
                text: item.id,
                message: 'Copied',
              });
            }}
            primary={
              <Typography variant="body1">
                {getValue(item.objectProperties.find((item) => item.key === 'valueValue').value)}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: valueProperty().key,
                  propValue: JSON.stringify(valueProperty().value),
                  propId: valueProperty().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={classes} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <PercentIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {getValue(item.objectProperties.find((item) => item.key === 'valuePercentage').value)}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: valuePercentageProperty().key,
                  propValue: JSON.stringify(valuePercentageProperty().value),
                  propId: valuePercentageProperty().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralCounterStatistic;
