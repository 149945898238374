import { SideLayoutContext } from '@src/context/side-layout/SideLayoutContext';
import { ReactNode, useRef } from 'react';

const SideLayoutProvider = ({ children }: { children: ReactNode }) => {
  const ref = useRef(null);
  const closeSideLayout = () => ref.current.resolveAll();

  return (
    <SideLayoutContext.Provider value={{ closeSideLayout, sideLayoutRef: ref }}>{children}</SideLayoutContext.Provider>
  );
};

export default SideLayoutProvider;
