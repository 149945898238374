import ImageItem from '@components/common/ImageItem';
import { SelectedOption } from '@components/common/ImageItem/components/MediaAutocomplete';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { FieldProps } from 'formik';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const Image = ({ form, field, label }: FieldProps<IFormValues> & { label: string }) => {
  const theme = useTheme();

  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" style={{ color: theme.palette.gray1 }}>
          Image
        </Typography>
      </Grid>
      <Grid item width="100%">
        <ImageItem
          {...fieldRequiredProps(form, validationSchema, field.name)}
          onChange={(e: SelectedOption) => {
            form.setFieldValue(field.name, e.target.value);
            if (e.target.value instanceof Blob) {
              form.setFieldValue('name', e.target.value.name);
            } else if (e.target?.rawValue?.title) {
              form.setFieldValue('name', e.target?.rawValue?.title);
            }
          }}
          label={label}
          withUnlink={true}
          withSearch={false}
        />
      </Grid>
    </>
  );
};

export default Image;
