import { useQuery } from '@apollo/client';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import StatusListItemNull from '@components/side-card/command-button/components/StatusListItemNull';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import useMoreMenu from '@components/useMoreMenu';
import AdvancedButtonEditControlModal from '@modules/widgets/forms/AdvancedButton/EditControlModal';
import EditObjectModal from '@modules/widgets/forms/CommandButton/EditObjectModal';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { GET_OBJECT_NAME_BY_ID } from '@shared/api/getObjectNameById';
import ControlListItem from './components/ControlListItem';
import ObjectListItem from './components/ObjectListItem';
import StatusListItem from './components/StatusListItem';
import st from './style.module.css';

const AdvancedButtonGeneralTab = (props) => {
  const { item, propKey } = props;

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  const buttonText = () => getPropertyByKey('valueText');
  const params = () => getPropertyByKey('valueParams')?.value;
  const valueActionRPC = getPropertyByKey('valueValue')?.value?.value;
  const objectId = () => getPropertyByKey(propKey)?.value?.objectId;

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: TextMenu, openMoreMenu: openTextMenu } = useMoreMenu();

  const { data: dataObject, loading } = useQuery(GET_OBJECT_NAME_BY_ID, {
    variables: {
      id: objectId(),
    },
  });

  const rpc = valueActionRPC;

  return (
    <List>
      <ColumnMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              EditObjectModal({
                widgetId: obj.id,
                propKey: propKey,
              })
                .then()
                .catch(() => {});
            },
          },
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            disabled: true,
            handleAction: (obj) => {
              HistoryCommonLayout({
                object: item,
                propertyId: obj.propId,
              }).catch(() => {});
            },
          },
        ]}
      />
      <TextMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit action',
            id: 'edit_prop',
            handleAction: (obj) => {
              AdvancedButtonEditControlModal({
                widgetId: obj.id,
                propId: buttonText().id,
                propKey: propKey,
              })
                .then()
                .catch(() => {});
            },
          },
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            disabled: true,
            handleAction: (obj) => {
              HistoryCommonLayout({
                object: item,
                propertyId: obj.propId,
              }).catch(() => {});
            },
          },
        ]}
      />
      <ListSubheader color="primary" className={st.listSubheader}>
        <Typography variant="subtitle2">Value</Typography>
      </ListSubheader>
      {objectId() && rpc ? <StatusListItem params={params} rpc={rpc} objectId={objectId()} /> : <StatusListItemNull />}
      <ListSubheader color="primary" className={st.listSubheader}>
        <Typography variant="subtitle2">Target</Typography>
      </ListSubheader>
      <ObjectListItem dataObject={dataObject} openColumnMenu={openColumnMenu} item={item} loading={loading} />
      <ControlListItem openTextMenu={openTextMenu} buttonText={rpc} item={item} />
      <ServiceSection classes={st} item={item} />
      <AccessSection classes={st} item={item} />
      {item.description && <DescriptionSection classes={st} item={item} />}
    </List>
  );
};

export default AdvancedButtonGeneralTab;
