import { useQuery } from '@apollo/client';
import { msg } from '@constants/messages';
import AddNewMediaModal from '@modules/widgets/forms/AddNewMediaModal';
import AddIcon from '@mui/icons-material/Add';
import { LinearProgress, ListItemButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { GET_OBJECT_WITH_MEDIA } from '@shared/api/getObjectWithMedia';
import { Object as ObjectType, ObjectsToObject } from '@src/__generated__/graphql';
import orderBy from 'lodash.orderby';
import LinkExistingMediaModal from 'modules/widgets/forms/LinkExistingMediaModal';

import ImageContainer from './components/ImageContainer';

interface IProps {
  item: ObjectType;
  refetch: () => void;
}

const TabContentMedia = ({ item }: IProps) => {
  const {
    loading,
    data: objectWithMedia,
    refetch,
  } = useQuery(GET_OBJECT_WITH_MEDIA, {
    variables: {
      objectId: item.id,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <>
      <div style={{ position: 'absolute', width: '100%', top: 0 }}>
        {loading && <LinearProgress style={{ width: '100%' }} />}
      </div>
      {!objectWithMedia && !loading && (
        <Typography style={{ textAlign: 'center', paddingTop: '10px' }} variant="body1">
          {' '}
          No data
        </Typography>
      )}
      {objectWithMedia && !loading && (
        <List>
          {orderBy(objectWithMedia.object?.objectsToObjectsByObject1Id, ['object2.name'], ['asc'])
            .filter((obj) => obj.object2?.schemaType === 'media')
            .map((object) => (
              <ListItem key={object.object2?.id}>
                <ImageContainer linkId={object.id} object={object.object2 as ObjectsToObject['object2']} />
              </ListItem>
            ))}
        </List>
      )}
      <ListItemButton
        data-test="addExistingMedia"
        onClick={() => {
          LinkExistingMediaModal({
            refetch,
            widget: item,
          }).catch(() => {});
        }}
      >
        <ListItemIcon>
          <IconButton size="small">
            <AddIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText primary={msg.sideCard.addExistingObject} />
      </ListItemButton>
      <ListItemButton
        data-test="addNewMedia"
        onClick={() => {
          AddNewMediaModal({
            refetch,
            widget: item,
          }).catch(() => {});
        }}
      >
        <ListItemIcon>
          <IconButton size="small">
            <AddIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText primary={'Add new media'} />
      </ListItemButton>
    </>
  );
};

export default TabContentMedia;
