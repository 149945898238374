import { msg } from '@constants/messages';
import { Button } from '@mui/material';
import { FC } from 'react';

interface IProps {
  handleClose: () => void;
  loading?: boolean;
  cancelTitle?: string;
  submitTitle?: string;
}

export const Buttons: FC<IProps> = ({
  handleClose,
  loading,
  cancelTitle = msg.default.cancel,
  submitTitle = msg.default.save,
}) => (
  <>
    <Button color="inherit" onClick={handleClose}>
      {cancelTitle}
    </Button>
    <Button color="primary" type="submit" disabled={loading}>
      {submitTitle}
    </Button>
  </>
);
