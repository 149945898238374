import { useLazyQuery, useMutation } from '@apollo/client';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import { REMOVE_OBJECT } from '@modules/objects/api/removeObject';
import { CREATE_OBJECT } from '@shared/api/createObject';
import { SEARCH_SCHEMA } from '@shared/api/searchSchema';
import useMedia from '@utils/useMedia';

type Options = {
  objectId: string;
  newImageId: string | null;
  prevImageId: string | null;
  unlinkWithRemove?: boolean;
  currentLinkId?: string;
};

type NewImageProperty = {
  name?: string;
  description?: string;
};

export const useUpdateBackgroundImage = () => {
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const [deleteObjects] = useMutation(REMOVE_OBJECT);
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [getMediaSchema] = useLazyQuery(SEARCH_SCHEMA, {
    variables: {
      filter: {
        name: {
          equalTo: 'Media image',
        },
      },
    },
    fetchPolicy: 'cache-first',
  });
  const [createObject] = useMutation(CREATE_OBJECT);

  const removeImage = async (imageId: string) => {
    await deleteObjects({
      variables: {
        input: {
          mnPatch: [{ id: imageId }],
        },
      },
    }).catch(() => {});
  };

  const unlinkImage = async (currentLinkId: string) => {
    if (currentLinkId) {
      await unlinkObjects({
        variables: {
          linkId: currentLinkId,
        },
      }).catch(() => {});
    }
  };

  const updateImageToObjectLink = async ({
    objectId,
    newImageId,
    prevImageId,
    unlinkWithRemove,
    currentLinkId,
  }: Options) => {
    if (prevImageId && newImageId !== prevImageId) {
      // unlink previous image
      if (currentLinkId) {
        await unlinkObjects({
          variables: {
            linkId: currentLinkId,
          },
        }).catch(() => {});
      }
      if (unlinkWithRemove) {
        await removeImage(prevImageId);
      }
    }
    if (newImageId && newImageId !== prevImageId) {
      // link new image
      await linkObjects({
        variables: {
          widgetId: objectId,
          objectId: newImageId,
        },
      }).catch(() => {});
    }
  };

  const { uploadMedia } = useMedia();
  const uploadImageFile = async (img: Blob | string | null, properties?: NewImageProperty) => {
    if (img instanceof Blob) {
      try {
        const schema = await getMediaSchema();
        const schemaId = schema?.data?.schemata[0]?.id;
        if (!schemaId) {
          console.error('Schema not found');
          return null;
        }
        const newMediaObject = await createObject({
          variables: {
            input: {
              object: {
                name: properties?.name ?? img.name,
                description: properties?.description ?? img.name,
                schemaId,
              },
            },
          },
        });
        if (!newMediaObject) {
          console.error('Cannot create new media object');
          return null;
        }
        const resMedia = await uploadMedia(img, null, newMediaObject?.data?.createObject?.object?.id as string);
        return resMedia?.data as string | null;
      } catch (e) {
        return null;
      }
    } else if (typeof img === 'string') {
      return img;
    } else {
      return null;
    }
  };

  const uploadImageWithoutLink = async (previousUid: string | null, newUid: string | Blob | null): Promise<string> => {
    const uploadingImageUid = await uploadImageFile(newUid);
    if (uploadingImageUid !== previousUid && previousUid) {
      await removeImage(previousUid);
    }
    return uploadingImageUid;
  };

  return { updateImageToObjectLink, uploadImageFile, uploadImageWithoutLink, removeImage, unlinkImage };
};
