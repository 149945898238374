import AdvancedButtonFallback from '@assets/fallbacks/advanced-button.svg';
import ChartFallback from '@assets/fallbacks/chart.svg';
import CollectionFallback from '@assets/fallbacks/collection.svg';
import CommandButtonFallback from '@assets/fallbacks/command-button.svg';
import ContainerFallback from '@assets/fallbacks/container.svg';
import DatasetFallback from '@assets/fallbacks/dataset.svg';
import DateTimeFallback from '@assets/fallbacks/datetime.svg';
import DiagramFallback from '@assets/fallbacks/diagram.svg';
import HistoryTableFallback from '@assets/fallbacks/history-table.svg';
import ImageBox from '@assets/fallbacks/image-box.svg';
import MinimapFallback from '@assets/fallbacks/minimap.svg';
import MonitorTableFallback from '@assets/fallbacks/monitor-table.svg';
import ObjectFallback from '@assets/fallbacks/object.svg';
import StaticTableFallback from '@assets/fallbacks/static-table.svg';
import StatisticFallback from '@assets/fallbacks/statistics.svg';
import TimerFallback from '@assets/fallbacks/timer.svg';
import TitleFallback from '@assets/fallbacks/title.svg';
import TrackingBox from '@assets/fallbacks/tracking-box.svg';
import WidgetFallback from '@assets/fallbacks/widget.svg';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import Arrow from '../../assets/fallbacks/lineWidget.svg';
import useMedia from '../../utils/useMedia';

const CardImage = ({ object, type }) => {
  const objectPropertiesMap = useMemo(() => getPropertiesMap(object.objectProperties), [object.objectProperties]);
  const { getImageById } = useMedia();
  const containerType = objectPropertiesMap['generalContainerType']?.value;
  const infoImage = objectPropertiesMap['infoImage']?.value;
  const bgImage = objectPropertiesMap['customBackgroundImage']?.value;
  const svgImage = object?.schema?.mPicture;
  const schemaType = object.schemaType;
  const schemaTags = object.schemaTags;

  const getImageFallback = () => {
    if (schemaTags.includes('statistics')) {
      return StatisticFallback;
    }

    if (schemaTags.includes('diagram')) {
      return DiagramFallback;
    }

    if (schemaTags.includes('monitor table')) {
      return MonitorTableFallback;
    }

    if (schemaTags.includes('line')) {
      return Arrow;
    }

    if (schemaTags.includes('monitor status')) {
      return MonitorTableFallback;
    }

    if (schemaTags.includes('static table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes(WIDGETS_ENUM.PROPERTY_HISTORY_TABLE)) {
      return HistoryTableFallback;
    }

    if (schemaTags.includes('tracking table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes('imagebox')) {
      return ImageBox;
    }

    if (schemaTags.includes('history table')) {
      return HistoryTableFallback;
    }

    if (schemaTags.includes('geotags table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes('datachart')) {
      return ChartFallback;
    }

    if (schemaTags.includes('color datachart')) {
      return ChartFallback;
    }

    if (schemaTags.includes('title')) {
      return TitleFallback;
    }

    if (schemaTags.includes('command button')) {
      return CommandButtonFallback;
    }

    if (schemaTags.includes('databox')) {
      return WidgetFallback;
    }

    if (schemaTags.includes('geo timer')) {
      return TrackingBox;
    }

    if (schemaTags.includes('datetime')) {
      return DateTimeFallback;
    }

    if (schemaTags.includes('timer')) {
      return TimerFallback;
    }

    if (schemaTags.includes('advanced button')) {
      return AdvancedButtonFallback;
    }

    switch (type) {
      case 'group':
        if (containerType === 'minimap') {
          return MinimapFallback;
        }
        return ContainerFallback;
    }

    if (type === 'object') {
      switch (schemaType) {
        case 'dataset':
          return DatasetFallback;
        default:
          return ObjectFallback;
      }
    }

    if (type === 'collection') {
      return CollectionFallback;
    }
  };

  const specificImageId = infoImage || bgImage || svgImage;

  return (
    <>
      {specificImageId && (
        <div
          style={{
            width: '100%',
            height: '240px',
            backgroundImage: `url(${getImageById(specificImageId)})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        ></div>
      )}

      {!specificImageId && <SVG src={getImageFallback()} width="100%" height="240px" />}
    </>
  );
};

export default CardImage;
