import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import MediaAutocomplete from '@components/common/ImageItem/components/MediaAutocomplete';
import { IFormValues, validationSchema } from '../../constants/formik';

const Image = ({ form, field, label }: FieldProps<IFormValues> & { label: string }) => {
  return (
    <Grid item width="100%">
      <MediaAutocomplete {...fieldRequiredProps(form, validationSchema, field.name)} label={label} />
    </Grid>
  );
};

export default Image;
