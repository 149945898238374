import mediaServerClient from './mediaServerClient';

const token = () => localStorage.getItem('authToken');

export function getObjectPropertiesHistory(data: unknown) {
  return mediaServerClient.post(`/objectpropertieshistory/${token()}`, data, {
    responseType: 'blob',
  });
}

export function getHistoryProperties(data: unknown) {
  return mediaServerClient.post(`/propertyhistory/${token()}`, data, {
    responseType: 'blob',
  });
}

export type ControlHistoryData = {
  objects: string[];
  from: string; // Date in format 'yyyy-MM-dd 00:00'
  to: string; // Date in format 'yyyy-MM-dd 00:00'
  rpc_names?: string[];
};

export function getControlsHistory(data: ControlHistoryData) {
  return mediaServerClient.post(`/controlshistory/${token()}`, data, {
    responseType: 'blob',
  });
}

export function getNotificationsHistory(data: unknown) {
  return mediaServerClient.post(`/notificationshistory/${token()}`, data, {
    responseType: 'blob',
  });
}

export function getNotificationsHistoryByUserId(data: unknown) {
  return mediaServerClient.post(`/notificationdeliverieshistory/${token()}`, data, {
    responseType: 'blob',
  });
}
