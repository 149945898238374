import { gql, useLazyQuery, useMutation } from '@apollo/client';
import ConditionModal from '@components/modals/ConditionModal';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import SelectSchema from '@components/modals/SelectSchema';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import tabStyle from '@components/side-card/tab.module.css';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { hideNull } from '@utils/hideNull';
import cloneDeep from 'lodash.clonedeep';
import { useEffect } from 'react';

const GET_SCHEMA = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
      schemaProperties(sort: { sortBy: { field: GROUP_NAME } }) {
        id
        key
        groupName
        property
        description
        type {
          name
        }
        valueSet
        valueRange
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const TabGeneralSource = (props) => {
  const { item } = props;

  const handleCopy = useHandleCopy();

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const conditions = () => {
    return item.objectProperties.find((item) => item.key === 'settingsFilter').value.conditions;
  };

  const conditionProperty = () => {
    return item.objectProperties.find((item) => item.key === 'settingsFilter');
  };

  const schemaId = () => {
    return item.objectProperties.find((item) => item.key === 'settingsSchemaid').value;
  };

  const settingsLinkedOnly = () => {
    return item.objectProperties.find((item) => item.key === 'settingsLinkedOnly');
  };

  const settingsSchemaId = () => {
    return item.objectProperties.find((item) => item.key === 'settingsSchemaid');
  };

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [loadSchema, { data }] = useLazyQuery(GET_SCHEMA, {
    variables: {
      id: schemaId(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const schemaName = () => {
    return data?.schema?.name || 'n/a';
  };

  const schemaProperties = () => {
    if (!data) return [];
    return data?.schema?.schemaProperties.map((item) => {
      return {
        value: `${item.groupName}/${item.property}`,
        title: `${item.groupName}/${item?.description || item.property}`,
        ...item,
      };
    });
  };

  const schemaRawProperties = () => {
    if (!data) return [];
    return data?.schema?.schemaProperties;
  };

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = conditionProperty();
    const oldValues = cloneDeep(conditionPropertyItem.value.conditions);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      filtering: Boolean(oldValues.length),
      conditions: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (schemaId()) {
      loadSchema().then(() => {});
    }
  }, [item]);

  return (
    <>
      <ValueMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              switch (obj.type) {
                case 'schema':
                  SelectSchema({
                    handleSave: handleUpdateProperty,
                    property: settingsSchemaId(),
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
                case 'linked':
                  EditDynamicProperty({
                    handleSave: handleUpdateProperty,
                    property: settingsLinkedOnly(),
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
              }
            },
            disabled: false,
          },
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              HistoryCommonLayout({
                object: item,
                propertyId: obj.propId,
              }).catch(() => {});
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: obj.propValue,
                message: 'Value copied',
              });
            },
            disabled: false,
          },
        ]}
      />
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Settings</Typography>
        </ListSubheader>

        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{schemaName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsSchemaId().key,
                  propValue: settingsSchemaId().value,
                  propId: settingsSchemaId().id,
                  type: 'schema',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{hideNull(settingsLinkedOnly().value) ? 'Linked only' : 'All'}</Typography>
            }
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsLinkedOnly().key,
                  propValue: settingsLinkedOnly().value,
                  propId: settingsLinkedOnly().id,
                  type: 'linked',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Filter</Typography>
        </ListSubheader>

        {conditions().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: tabStyle.itemToHover,
              }}
              style={{ height: '48px', paddingRight: '96px' }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon />
              <ListItemText
                primary={<Typography variant="body1">{`${item.property} ${item.operator} ${item.value}`}</Typography>}
                onClick={() => {}}
              />
              <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
                <IconButton
                  size="small"
                  onClick={() => {
                    ConditionModal({
                      condition: item,
                      isEdit: true,
                      conditionIndex: index,
                      conditionProperty: conditionProperty(),
                      properties: schemaProperties(),
                      schemaRawProperties: schemaRawProperties(),
                    }).catch(() => {});
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: '48px' }}
          button
          onClick={() => {
            ConditionModal({
              isEdit: false,
              conditionProperty: conditionProperty(),
              properties: schemaProperties(),
              schemaRawProperties: schemaRawProperties(),
            }).catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add condition</Typography>} />
        </ListItem>
      </List>
    </>
  );
};

export default TabGeneralSource;
