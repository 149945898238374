import { useLazyQuery } from '@apollo/client';
import InlineLoader from '@components/common/InlineLoader';
import LabelIcon from '@components/icons/labelIcon';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import HumanReadableProperty from '@components/side-card/geo-timer/HumanReadableProperty';
import PropListItem from '@components/side-card/PropListItem';
import tabStyle from '@components/side-card/tab.module.css';
import { DEVICE_PROP_LINKED_QUERY } from '@graphql/queries';
import ExtensionIcon from '@mui/icons-material/Extension';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { GET_OBJECT_NAME_BY_ID } from '@shared/api/getObjectNameById';
import { GET_OBJECT_PROPERTY } from '@shared/api/getObjectProperty';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { isMonitoringObject } from '@utils/objectType';
import { updatedBy } from '@utils/updatedBy';
import { Suspense, useEffect, useState } from 'react';

interface IProps {
  item: ObjectType;
  dashboardId: string;
  groupId: string;
  widgetId: string;
}

const DataboxGeneralTab = ({ item, dashboardId, groupId, widgetId }: IProps) => {
  const [loadObjectProperty, { loading: loadingProperty }] = useLazyQuery(GET_OBJECT_PROPERTY, {
    fetchPolicy: 'cache-first',
  });
  const [loadDevicePropertyLinkedData, { loading: loadingDevicePropertyData }] = useLazyQuery(DEVICE_PROP_LINKED_QUERY);
  const [objectName, setObjectName] = useState('');
  const [units, setUnits] = useState(null);
  const [propertyName, setPropertyName] = useState('');
  const isLoading = loadingProperty || loadingDevicePropertyData;

  const objectPropertiesMap = getPropertiesMap(item?.objectProperties);

  const valueValue = objectPropertiesMap?.['valueValue'];
  const currentIcon = objectPropertiesMap?.['valueCurrentIcon'];
  const currentLabel = objectPropertiesMap?.['valueCurrentLabel'];

  const linkedObject = (key: string) => {
    return key === 'valueValue' ? item.objectsToObjectsByObject1Id : '';
  };

  const currentIconName = () => {
    const filtersGroup = currentIcon?.value;

    return {
      query: GET_OBJECT_NAME_BY_ID,
      config: {
        variables: {
          id: filtersGroup,
        },
      },
    };
  };

  const getLoadedValue = (loader: boolean, value: string): string => {
    if (loader) {
      return 'Loading...';
    }
    if (!loader && value) {
      return value;
    }
    return 'n/a';
  };

  useEffect(() => {
    const linkedPropertyId = valueValue?.linkedPropertyId;

    if (linkedPropertyId) {
      void loadObjectProperty({
        variables: {
          id: linkedPropertyId,
        },
      }).then(async (res) => {
        const objectPropertyUnits = res.data?.objectProperty?.spec?.units;
        if (isMonitoringObject(res.data.objectProperty.object.schemaTags)) {
          const linkedData = await loadDevicePropertyLinkedData({
            variables: {
              linkedPropId: linkedPropertyId,
            },
            fetchPolicy: 'cache-first',
          });
          const fragments = linkedData.data.objectProperty.object.objectsToObjectsByObject2Id[0];
          const fragmentUnits = res.data.objectProperty.object.objectProperties.find(
            (prop) => prop.property === 'Units'
          )?.value;
          let linkedPropertyUnits: string;
          if (!fragmentUnits) {
            const objectProperty = await loadObjectProperty({
              variables: {
                id: linkedData.data.objectProperty.linkedPropertyId,
              },
            });
            linkedPropertyUnits = objectProperty.data?.objectProperty?.spec?.units;
          }
          setUnits(fragmentUnits || linkedPropertyUnits || objectPropertyUnits);
          setObjectName(fragments?.forced === 0 ? fragments.object2.name : fragments.object1.name);
          setPropertyName(res.data?.objectProperty.object.name);
        } else {
          setObjectName(res.data?.objectProperty.object.name);
          setPropertyName(res.data.objectProperty.spec.description || res.data.objectProperty.property);
          setUnits(objectPropertyUnits);
        }
      });
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <PropListItem
          linkedObjects={linkedObject('valueValue')}
          tab="general"
          obj={item}
          units={units}
          item={valueValue}
          dashboardId={dashboardId}
          widgetId={widgetId}
          groupId={groupId}
        />

        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentIcon?.updatedAt, currentIcon?.userByBy)}
            primary={
              <Typography variant="body1">
                Current icon:
                {currentIcon?.value && (
                  <>
                    <Suspense fallback={<InlineLoader />}>
                      <HumanReadableProperty
                        payload={currentIconName()}
                        getValue={(data) => ` ${data?.object?.name}` || ' n/a'}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!currentIcon?.value && 'n/a'}
              </Typography>
            }
            onClick={() => {}}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentLabel?.updatedAt, currentLabel?.userByBy)}
            primary={<Typography variant="body1">Current label: {currentLabel?.value || 'n/a'}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Source</Typography>
        </ListSubheader>

        <ListItem className={tabStyle.listItemWithTools}>
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography title={getLoadedValue(isLoading, objectName)} noWrap variant="body1">
                Object: {getLoadedValue(isLoading, objectName)}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LabelIcon></LabelIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Property: {getLoadedValue(isLoading, propertyName)}</Typography>}
          />
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default DataboxGeneralTab;
