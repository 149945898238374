import { gql } from '@src/__generated__';

export const CREATE_OBJECT = gql(/* GraphQL */ `
  mutation createObject($input: CreateObjectInput!) {
    createObject(input: $input) {
      object {
        id
        name
      }
    }
  }
`);
