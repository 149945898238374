import { gql } from '@src/__generated__';

// the same DEVICES_LINKED_QUERY
const GET_DEVICES_LINKED = gql(/* GraphQL */ `
  query listConnectedObjects($widgetId: UUID!) {
    objects(filter: { objectsToObjectsByObject2IdConnection: { some: { object1: { id: { equalTo: $widgetId } } } } }) {
      id
      name
      objectsToObjectsByObject1Id {
        forced
        object2 {
          id
        }
      }
    }
  }
`);

export { GET_DEVICES_LINKED };
