import imageboxDefault from '@assets/imageboxDefault.svg';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import useMenu from '@components/side-card/menu/useMenu';
import useMoreMenu from '@components/useMoreMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { ObjectsToObject } from '@src/__generated__/graphql';
import { formatBytes } from '@utils/formatBytes';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import useMedia from '@utils/useMedia';

import st from './style.module.css';

interface IProps {
  linkId: string;
  object: ObjectsToObject['object2'];
}

const ImageContainer = ({ linkId, object }: IProps) => {
  const { getImageById } = useMedia();
  const objectPropertiesMap = getPropertiesMap(object.objectProperties);
  const { genUnlinkItem, genMenuDelete } = useMenu();
  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  const { unlinkImage, removeImage } = useUpdateBackgroundImage();

  const getImageURL = (img: string | Blob | null) => {
    if (img instanceof Blob) {
      return URL.createObjectURL(img);
    }
    if (typeof img === 'string') {
      return getImageById(img);
    }
    return imageboxDefault;
  };

  return (
    <>
      <MoreMenu
        items={[
          genUnlinkItem({
            handleAction: async () => {
              try {
                await unlinkImage(linkId);
                // eslint-disable-next-line no-empty
              } catch (e) {}
            },
          }),
          genMenuDelete({
            handleAction: async () => {
              try {
                await unlinkImage(linkId);
                await removeImage(object.id as string);
                // eslint-disable-next-line no-empty
              } catch (e) {}
            },
          }),
        ]}
        handleMenuItemClick={closeMoreMenu}
      />
      <Card className={st.cardContainer}>
        <CardMedia component="img" height="200" image={getImageURL(object.id as string)} alt={object.name} />
        <CardContent className={st.cardInfoBox}>
          <Box>
            <Typography noWrap title={object.name} variant="h6">
              {object.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                {objectPropertiesMap['credentialsType']?.value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatBytes(objectPropertiesMap['credentialsSize']?.value as number)}
              </Typography>
            </Box>
          </Box>

          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              e.preventDefault();
              openMoreMenu(e, object);
            }}
            className={st.action}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </CardContent>
      </Card>
    </>
  );
};
export default ImageContainer;
