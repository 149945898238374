import { gql } from '@src/__generated__';

export const GET_OBJECT_NAME_BY_ID = gql(/* GraphQL */ `
  query getObjectNameById($id: UUID!) {
    object(id: $id) {
      id
      name
    }
  }
`);
