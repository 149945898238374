import { gql, useQuery } from '@apollo/client';
import { msg } from '@constants/messages';
import { RootState } from '@src/store';
import { version } from '@src/version';
import { useAuth } from '@utils/useAuth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IListItem } from '../components/ListItem';

const GET_VERSION_QUERY = gql`
  query getVersion {
    getVersion {
      short
      long
    }
  }
`;
const GET_TIMEZONE_QUERY = gql`
  query getTimezone {
    getTimezone
  }
`;

const useAboutModalData = () => {
  const { loading: isVersionLoading, error, data } = useQuery(GET_VERSION_QUERY);
  const { data: timezoneData, loading: isTimezoneLoading } = useQuery(GET_TIMEZONE_QUERY);

  const aboutInfo = useSelector(
    (state: RootState) => (state?.profile?.aboutInfo as Record<string, string>)?.content || ''
  );

  const { loadUser, isLoadingUser } = useAuth();

  useEffect(() => {
    void loadUser();
  }, []);

  const dataStructure: IListItem[] = [
    { name: 'Application', value: '', type: 'title' },
    { name: msg.aboutModal.pixelCoreVer, value: data?.getVersion?.short ?? '', type: 'text' },
    { name: msg.aboutModal.pixelFrontVer, value: version, type: 'text' },
    { name: msg.aboutModal.pixelCoreTimeZone, value: timezoneData?.getTimezone ?? '', type: 'text' },
    { name: 'About us', value: '', type: 'title' },
  ];

  return {
    loading: isVersionLoading || isTimezoneLoading || isLoadingUser,
    error,
    data: dataStructure,
    aboutInfo,
  };
};

export default useAboutModalData;
