import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { BACKGROUND_OPTIONS, IFormValues, validationSchema } from '../../constants/formik';

const Background: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'background')}
        label="Background"
        list={BACKGROUND_OPTIONS}
      />
    </Grid>
  );
};

export default Background;
