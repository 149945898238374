import { gql, useMutation } from '@apollo/client';
import { useSelectWidget } from '@components/create-widget/useSelectWidget';
import DeleteWidgetModal from '@components/DeleteWidgetModal';
import EditColorWidgetChartModal from '@components/EditColorWidgetChartModal';
import EditWidgetChartModal from '@components/EditWidgetChartModal';
import EditWidgetColorsModal from '@components/EditWidgetColorsModal';
import EditWidgetModal from '@components/EditWidgetModal';
import EditWidgetTableChartModal from '@components/EditWidgetTableChartModal';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import ChangeAccessModal from '@components/modals/ChangeAccessModal/ChangeAccessModal';
import DateModal from '@components/modals/DateModal';
import EditCalculator from '@components/modals/EditCalculator';
import EditCounter from '@components/modals/EditCounter';
import EditTimerColorsModal from '@components/modals/EditTimerColorsModal';
import EditTimeseries from '@components/modals/EditTimeseries';
import EditColorsTrackingBox from '@components/side-card/geo-timer/EditColorsTrackingBox';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import { HistoryControlsLayout } from '@components/side-card/history-controls/HistoryControlsLayout';
import EditObjectStatisticModal from '@components/side-card/object-statistic/EditObjectStatisticModal';
import downloadCSVGeotagsTable from '@components/widgets/geotags-table/utils/downloadCsv';
import { downloadCSV as downloadCSVPropertyHistory } from '@components/widgets/property-history-table/utils/downloadCsv';
import { downloadCSV as downloadCSVStaticTable } from '@components/widgets/static-table/utils/downloadCsv';
import LinkTitleModal from '@components/widgets/title/LinkTitleModal';
import { downloadCSVMonitorTable } from '@components/widgets/W_MonitorTable/utils/downloadCSV';
import useRoute from '@hooks/useRoute';
import SendNotificationModal from '@modules/notifications/components/SendNotificationModal';
import downloadNotifications from '@utils/downloadNotifications';
import { getPropertyByKey, getPropertyValueByKey } from '@utils/getPropertyByKey';
import {
  isAdvancedButton,
  isCalculator,
  isChart,
  isColorChart,
  isCommandButton,
  isCounter,
  isDatabox,
  isGeotagsTable,
  isMonitorTable,
  isObjectStatistic,
  isPropertyHistoryTable,
  isStaticTable,
  isTimeseries,
  isTitle,
  isTrackingBox,
  isTrackingTable,
} from '@utils/objectType';
import { format, parseISO } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import toast from 'react-hot-toast';
import useHandleCopy from './handlers/useHandleCopy';
import useMenu from './useMenu';

const UPDATE_MUTING_OBJECT = gql`
  mutation update($input: SetObjectMutedInput!) {
    setObjectMuted(input: $input) {
      clientMutationId
    }
  }
`;

export default function useWidgetMenu(object) {
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();
  const [updateMutingObject] = useMutation(UPDATE_MUTING_OBJECT);

  const widgetType = object.schemaTags[3];
  const group =
    object?.objectsToObjectsByObject2Id?.find((item) => item.object1.schemaTags?.includes('group'))?.object1 || null;

  const groupPrepared = group
    ? {
        ...group,
        type: [
          {
            id: getPropertyByKey(group.objectProperties, 'generalContainerType')?.id,
            value: getPropertyValueByKey(group.objectProperties, 'generalContainerType'),
          },
        ],
        layouts: [
          {
            id: getPropertyByKey(group.objectProperties, 'generalLayouts')?.id,
            value: getPropertyValueByKey(group.objectProperties, 'generalLayouts'),
          },
        ],
      }
    : null;

  const { selectByType } = useSelectWidget({ group: groupPrepared });

  const showOptionalColors = () => {
    return items.getMenuOptionalColors({
      handleAction: () => {
        EditWidgetColorsModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showTimerColorsColors = () => {
    return items.getMenuOptionalColors({
      handleAction: () => {
        EditTimerColorsModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showTrackingBoxColors = () => {
    return items.getMenuOptionalColors({
      handleAction: () => {
        EditColorsTrackingBox({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showLinkMenu = () => {
    return items.getLink({
      handleAction: () => {
        LinkTitleModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showDownloadCsvStaticTable = () => {
    return items.getGenCsv({
      handleAction: () => {
        const device = object.objectProperties.find((item) => item.key === 'settingsObject')?.value;
        downloadCSVStaticTable(object, device);
      },
    });
  };

  const showDownloadCsvPropertyHistoryTable = () => {
    return items.getGenCsv({
      handleAction: () => {
        const settingsProperty = object.objectProperties.find((item) => item.key === 'settingsProperty')?.value;
        downloadCSVPropertyHistory(object, settingsProperty);
      },
    });
  };

  const showDownloadCSVGeotagsTable = () => {
    return items.getGenCsv({
      handleAction: () => {
        const settingsObject = object.objectProperties.find((item) => item.key === 'settingsObject')?.value;
        downloadCSVGeotagsTable(object, settingsObject);
      },
    });
  };

  const showDownloadCsvMonitorTable = () => {
    return items.getGenCsv({
      handleAction: () => {
        downloadCSVMonitorTable(object, object.name);
      },
    });
  };

  const showDownloadCsvTrackingTable = () => {
    return items.getGenCsv({
      handleAction: () => {
        const value = object.objectProperties.find((item) => item.key === 'valueValue')?.value || [];

        if (value.length > 0) {
          const headers = object.objectProperties.find((item) => item.key === 'settingsPeriods')?.value || [];

          let prepareForExport = [];

          value.forEach((item) => {
            const localItem = {
              Date: format(parseISO(item['date']), 'dd-MM-yyyy'),
              Geotag: item['geotag'],
            };

            item.periods.forEach((itemValueInRow) => {
              const name = headers.find((item) => item.id === itemValueInRow.id)?.period?.join('—');

              if (!name) {
                localItem[itemValueInRow.id] = itemValueInRow.value;
              } else {
                localItem[name] = itemValueInRow.value;
              }
            });

            prepareForExport.push(localItem);
          });

          const csvExporter = new ExportToCsv({
            title: object.name,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            useTextFile: false,
            filename: object.name,
            useBom: true,
            useKeysAsHeaders: true,
          });

          csvExporter.generateCsv(prepareForExport.reverse());
        } else {
          toast.error('No data available for export');
        }
      },
    });
  };

  const showEditChart = () => {
    return items.getMenuEditChart({
      handleAction: () => {
        EditWidgetChartModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showEditColorChart = () => {
    return items.getMenuEditChart({
      handleAction: () => {
        EditColorWidgetChartModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showEditTableChart = () => {
    return items.getMenuEditTableChart({
      handleAction: () => {
        EditWidgetTableChartModal({
          widgetId: object.id,
        }).catch(() => {});
      },
    });
  };

  const showHistoryControls = () => {
    return items.genMenuShowControlsHistory({
      handleAction: () => {
        const { dashboardId, reportId, groupId } = getParamsByRoute();

        if (dashboardId) {
          navigate(`/boards/${dashboardId}/${groupId}/${object.id}`);
        }

        if (reportId) {
          navigate(`/reports/${reportId}/${groupId}/${object.id}`);
        }
        HistoryControlsLayout({
          object,
        }).catch(() => {});
      },
    });
  };

  return [
    items.genMenuEditItem({
      handleAction: () => {
        if (isCounter(object.schemaTags)) {
          EditCounter({ object }).catch(() => {});
        } else if (isObjectStatistic(object.schemaTags)) {
          EditObjectStatisticModal({ object }).catch(() => {});
        } else if (isTimeseries(object.schemaTags)) {
          EditTimeseries({ object }).catch(() => {});
        } else if (isCalculator(object.schemaTags)) {
          EditCalculator({ object }).catch(() => {});
        } else {
          if (groupPrepared) {
            selectByType({
              widgetType,
              name: object.name,
              widget: object,
              group: groupPrepared,
            });
          } else {
            EditWidgetModal({ object }).catch(() => {});
          }
        }
      },
    }),
    items.genMenuAccess({
      handleAction: () => {
        ChangeAccessModal({ object, isWidget: true }).then();
      },
    }),
    // items.getMenuDisableItem({
    //   disabled: true,
    // }),
    items.genMenuMuteItem({
      muted: Boolean(object?.muted),
      handleAction: async () => {
        await updateMutingObject({
          variables: {
            input: {
              muted: !object?.muted,
              objectsId: object?.id,
            },
          },
        });
      },
    }),
    ...(isTitle(object.schemaTags) ? [showLinkMenu()] : []),
    ...(isDatabox(object.schemaTags) ? [showOptionalColors()] : []),
    ...(isTrackingBox(object.schemaTags) ? [showTrackingBoxColors()] : []),
    ...(isChart(object.schemaTags) ? [showEditChart()] : []),
    ...(isColorChart(object.schemaTags) ? [showEditColorChart()] : []),
    ...(isChart(object.schemaTags) || isColorChart(object.schemaTags) ? [showEditTableChart()] : []),
    // items.genMenuCreateCopy({
    //   disabled: true,
    // }),
    items.genMenuSendItem({
      handleAction: () => {
        SendNotificationModal({
          object,
        }).catch(() => {});
      },
    }),
    items.genMenuShowHistory({
      handleAction: () => {
        const { dashboardId, reportId, groupId } = getParamsByRoute();

        if (dashboardId) {
          navigate(`/boards/${dashboardId}/${groupId}/${object.id}`);
        }

        if (reportId) {
          navigate(`/reports/${reportId}/${groupId}/${object.id}`);
        }
        HistoryCommonLayout({
          object,
          propertyId: '',
        }).catch(() => {});
      },
    }),
    ...(isAdvancedButton(object.schemaTags) || isCommandButton(object.schemaTags) ? [showHistoryControls()] : []),
    items.genMenuDownloadNotifications({
      handleAction: () => {
        DateModal({
          downloadHistory: downloadNotifications,
          downloadIds: [object.id],
        }).catch(() => {});
      },
    }),
    ...(isStaticTable(object.schemaTags) ? [showDownloadCsvStaticTable()] : []),
    ...(isTrackingTable(object.schemaTags) ? [showDownloadCsvTrackingTable()] : []),
    ...(isMonitorTable(object.schemaTags) ? [showDownloadCsvMonitorTable()] : []),
    ...(isPropertyHistoryTable(object.schemaTags) ? [showDownloadCsvPropertyHistoryTable()] : []),
    ...(isGeotagsTable(object.schemaTags) ? [showDownloadCSVGeotagsTable()] : []),
    items.genCopyUUID({
      handleAction: () => handleCopy({ object }),
    }),
    items.genMenuDelete({
      disabled: false,
      handleAction: () => {
        const { groupId } = getParamsByRoute();

        DeleteWidgetModal({
          widgetId: object.id,
          groupId: groupId,
          name: object.name,
        }).catch(() => {});
      },
    }),
  ];
}
