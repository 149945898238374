import { ApolloQueryResult } from '@apollo/client';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import CommonModal from '@components/CommonModal';
import { Buttons } from '@components/CommonModal/components/Buttons';
import Grid from '@mui/material/Grid';
import { GetObjectWithMediaQuery, Object as ObjectType } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import Description from './components/Description';
import Image from './components/Image';
import Name from './components/Name';
import { IFormValues, validationSchema } from './constants/formik';

type IProps = {
  widget?: ObjectType;
  refetch: () => Promise<ApolloQueryResult<GetObjectWithMediaQuery>>;
};

const AddNewMediaModal = ({ onResolve, isOpen, widget, refetch }: IProps & InstanceProps<{}>) => {
  const { updateImageToObjectLink, uploadImageFile } = useUpdateBackgroundImage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = {
    initialValues: {
      name: '',
      description: '',
      image: null,
    },
    validationSchema,
    onSubmit: async ({ name, description, image }: IFormValues) => {
      if (image) {
        setIsSubmitting(true);
        try {
          const imageUid = await uploadImageFile(image, { name, description });
          await updateImageToObjectLink({
            newImageId: imageUid,
            prevImageId: null,
            objectId: widget.id,
          });
          await refetch();
        } finally {
          setIsSubmitting(false);
        }
        onResolve();
      }
    },
  };

  return (
    <CommonModal
      key="AddNewMedia"
      modalOpen={isOpen}
      title="Add new media"
      handleClose={onResolve}
      isForm={true}
      buttons={<Buttons loading={isSubmitting} handleClose={onResolve} />}
      loading={isSubmitting}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="name" placeholder="name" component={Name} />
        <FastField name="image" placeholder="image" label="Image" component={Image} />
        <FastField name="description" placeholder="description" component={Description} />
      </Grid>
    </CommonModal>
  );
};
export default create(AddNewMediaModal);
