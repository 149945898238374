import { ApolloQueryResult, useMutation } from '@apollo/client';
import CommonModal from '@components/CommonModal';
import { Buttons } from '@components/CommonModal/components/Buttons';
import { LINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import Grid from '@mui/material/Grid';
import { GetObjectWithMediaQuery, Object as ObjectType } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { create, InstanceProps } from 'react-modal-promise';

import Image from './components/Image';
import { IFormValues, validationSchema } from './constants/formik';

type IProps = {
  widget?: ObjectType;
  refetch: () => Promise<ApolloQueryResult<GetObjectWithMediaQuery>>;
};

const LinkExistingMediaModal = ({ onResolve, isOpen, widget, refetch }: IProps & InstanceProps<{}>) => {
  const [linkObjects, { loading }] = useMutation(LINK_OBJECTS);

  const formik = {
    initialValues: {
      image: null,
    },
    validationSchema,
    onSubmit: async ({ image }: IFormValues) => {
      if (image) {
        try {
          await linkObjects({
            variables: {
              widgetId: widget.id,
              objectId: image,
            },
          });
          await refetch();
          // eslint-disable-next-line no-empty
        } catch (e) {}
        onResolve();
      }
    },
  };

  return (
    <CommonModal
      key="LinkMedia"
      modalOpen={isOpen}
      title="Link existing media"
      handleClose={onResolve}
      isForm={true}
      buttons={<Buttons loading={loading} handleClose={onResolve} />}
      loading={loading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="image" placeholder="image" label="Image" component={Image} />
      </Grid>
    </CommonModal>
  );
};
export default create(LinkExistingMediaModal);
