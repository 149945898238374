import { useMutation } from '@apollo/client';
import CommonModal from '@components/CommonModal';
import { Buttons } from '@components/CommonModal/components/Buttons';
import { CREATE_NOTIFICATION } from '@modules/notifications/api/createNotification';
import { Grid } from '@mui/material';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { FC } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { IFormValues, initialValues, validationSchema } from './constants';

import { MessageInput } from './components/MessageInput';
import { TagsInput } from './components/TagsInput';

interface IProps {
  onResolve: () => void;
  isOpen: boolean;
  object: ObjectType;
}

const SendNotificationModal: FC<IProps & InstanceProps<{}>> = ({ object, isOpen, onResolve: handleClose }) => {
  const [createNotification, { loading: savingLoading }] = useMutation(CREATE_NOTIFICATION);

  const formik = {
    initialValues,
    validationSchema,
    onSubmit: async (values: IFormValues) => {
      const { tags, message } = values;
      await createNotification({
        variables: {
          input: {
            notification: { objectId: object?.id, objectName: object?.name, message, tags },
          },
        },
      });
      handleClose();
    },
  };

  return (
    <CommonModal
      modalOpen={isOpen}
      formik={formik}
      isForm
      loading={savingLoading}
      key="sendNotification"
      title="Send notification"
      handleClose={handleClose}
      buttons={<Buttons submitTitle={'Send'} loading={savingLoading} handleClose={handleClose} />}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name={'tags'} label={'Tags'} component={TagsInput} />
        <FastField clearFieldIcon={true} multiline name={'message'} label={'Message'} component={MessageInput} />
      </Grid>
    </CommonModal>
  );
};

export default create(SendNotificationModal);
