import DateModal from '@components//modals/DateModal';
import useMoreMenu from '@components/useMoreMenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TodayIcon from '@mui/icons-material/Today';
import { Divider, IconButton, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { formatISO, sub } from 'date-fns';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { InstanceProps } from 'react-modal-promise';
import st from './style.module.css';

type HistoryControlsToolbarProps = {
  name: string;
  setRecordedFilter: Dispatch<SetStateAction<string[]>>;
  setAfter: (value: string) => void;
  downloadHistory: (range: [Date, Date], ids: string[], shape?: 'wide' | 'long') => Promise<void>;
  type: string;
  downloadIds: string[];
  onClose: InstanceProps<{}>['onResolve'];
};

const HistoryControlsToolbar: FC<HistoryControlsToolbarProps> = (props) => {
  const { name, setRecordedFilter, setAfter, downloadHistory, downloadIds, onClose } = props;

  const { MoreMenu: HistoryControlsMenu, openMoreMenu: openHistorControlsMenu } = useMoreMenu();

  const [date, setDate] = useState('day');

  const menu = [
    {
      icon: <TodayIcon />,
      title: 'Day',
      id: 'day',
      disabled: false,
      checked: date === 'day',
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { days: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('day');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Week',
      id: 'week',
      checked: date === 'week',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { weeks: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('week');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Month',
      id: 'month',
      checked: date === 'month',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { months: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('month');
      },
    },
    {
      id: 'divider',
    },
    {
      icon: <GetAppIcon />,
      title: 'Download',
      id: 'download history',
      disabled: false,
      handleAction: () => {
        DateModal({
          downloadHistory,
          downloadIds,
          enableWide: true,
          type: props.type,
        })
          .then()
          .catch(() => {});
      },
    },
  ];
  return (
    <>
      <HistoryControlsMenu checkable items={menu} />
      <Toolbar variant="dense" className={st.header}>
        <IconButton className={st.backButton} onClick={onClose} size="medium">
          <ArrowBackIcon />
        </IconButton>
        <Typography noWrap variant="h6" className={st.name} title={name}>
          {name}
        </Typography>
        <IconButton aria-label="settings" onClick={openHistorControlsMenu} size="large" data-test-settings="settings">
          <MoreVertIcon />
        </IconButton>
      </Toolbar>

      <Divider />
    </>
  );
};

export default HistoryControlsToolbar;
