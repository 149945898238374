import { SettingsSizeOptions } from '@utils/constants/selectOptions';
import * as yup from 'yup';

export enum FormatOptionsEnum {
  filling = 'filling',
  savingscale = 'savingscale',
}

export const FORMAT_OPTIONS = [
  { title: 'Filling', value: FormatOptionsEnum.filling },
  { title: 'Saving scale', value: FormatOptionsEnum.savingscale },
];

export enum BackgroundOptionsEnum {
  light = 'light',
  transparent = 'transparent',
}
export const BACKGROUND_OPTIONS = [
  { title: 'Light', value: BackgroundOptionsEnum.light },
  { title: 'Transparent', value: BackgroundOptionsEnum.transparent },
];

export const SETTINGS_SIZE_OPTIONS = [
  {
    value: SettingsSizeOptions.small,
    title: 'Small',
  },
  {
    value: SettingsSizeOptions.medium,
    title: 'Medium',
  },
  {
    value: SettingsSizeOptions.large,
    title: 'Large',
  },
];

export interface IFormValues {
  name: string;
  format: string;
  size: string;
  description: string;
  background: string;
}

export const validationSchema = yup.object({});
