import { useApolloClient } from '@apollo/client';
import ConfirmationModal from '@components/modals/ConfirmationModal';
import { msg } from '@constants/messages';
import { DELETE_OBJECT_MUTATION } from '@graphql/queries';
import useRoute from '@hooks/useRoute';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';
import { useParams } from 'react-router-dom';

const useDeleteObject = (objectId: string, onSuccess: () => void) => {
  const client = useApolloClient();
  const { handleGoBack } = useRoute();
  const { objectId: objectIdParams } = useParams();
  return useCallback(async () => {
    const toastId = toast.loading('Deleting object...');
    try {
      await client.mutate({
        mutation: DELETE_OBJECT_MUTATION,
        variables: { objId: objectId },
      });
      toast.dismiss(toastId);
      toast.success('Object deleted successfully.');
      if (objectIdParams === objectId) {
        handleGoBack();
      }
      onSuccess();
    } catch (err) {
      toast.dismiss(toastId);
      if (err instanceof Error) {
        toast.error(err.message || 'An error occurred.', {
          position: 'bottom-center',
        });
      }
    }
  }, [client, objectId, onSuccess]);
};

interface IProps {
  objectId: string;
}

const DeleteObjectModal = ({ isOpen, objectId, onResolve, onReject }: IProps & InstanceProps<{}>) => {
  const handleDelete = useDeleteObject(objectId, () => {
    onResolve();
  });

  const handleClose = useCallback(() => onReject(), [onReject]);

  return (
    <ConfirmationModal
      modalOpen={isOpen}
      title={msg.deleteGroupModal.deleteGroup}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="inherit" onClick={handleClose}>
            {msg.deleteDashboardModal.buttonCancel}
          </Button>
          <Button
            color="primary"
            data-delete-dashboard
            onClick={() => {
              void handleDelete();
            }}
          >
            {msg.deleteDashboardModal.buttonDelete}
          </Button>
        </>
      }
    >
      {`Delete this object? This action is irreversible.`}
    </ConfirmationModal>
  );
};

export default create(DeleteObjectModal);
