import { gql } from '@src/__generated__';

export const GET_PAGED_CONTROLS = gql(/* GraphQL */ `
  query getPagedControls(
    $first: Int
    $after: Cursor
    $filter: ControlExecutionFilter
    $orderBy: [ControlExecutionsOrderBy!]
  ) {
    controlExecutionsConnection(first: $first, after: $after, filter: $filter, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          nodeId
          params
          type
          id
          linkedControlId
          name
          createdAt
          objectId
          caller {
            mName
          }
          ack
          done
          error
          callerId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);
