import st from './style.module.css';

const InlineLoader = () => {
  return (
    <span className={st.loader}>
      Loading<span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};

export default InlineLoader;
