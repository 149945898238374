import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { FORMAT_OPTIONS, IFormValues, validationSchema } from '../../constants/formik';

const Format: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item>
    <CustomSelect {...fieldRequiredProps(form, validationSchema, 'format')} label="Format" list={FORMAT_OPTIONS} />
  </Grid>
);

export default Format;
