import { ProcessItemType } from '@components/side-card/history-common/HistoryCommonLayout/constants';
import { getHistoryProperties, getObjectPropertiesHistory } from '@src/media-server';
import { ObjectPropertiesHistoriesEdge } from '@src/__generated__/graphql';
import downloadBlob from '@utils/downloadBlob';
import { format } from 'date-fns';

export const processItem = (
  item: ObjectPropertiesHistoriesEdge,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectPropertiesMap: Record<string, any>
): ProcessItemType => {
  const unit: string | undefined = objectPropertiesMap[item?.node?.propertyId]?.spec?.units;
  const computeValue = (value: unknown) => {
    if (value === null) return 'n/a';

    if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return `${String(value)} ${unit}`;
    }
  };

  const by = () => {
    const author = item.node?.userByBy?.mName;
    return author ? `by ${author}` : '';
  };

  const humanReadable = (propertyId: string): string => {
    const sameProperty = objectPropertiesMap[propertyId];
    const description: string = sameProperty?.spec?.description ?? '';

    if (description) {
      return description;
    }

    return objectPropertiesMap[propertyId]?.property as string;
  };

  return {
    secondary: `${format(new Date(item?.node?.recordedAt as string), 'MMM d, hh:mm:ss a')} ${by()}`,
    key: item.node.id,
    primary: `${humanReadable(item?.node?.propertyId as string)}: ${computeValue(item.node.value)}`,
    humanReadable: humanReadable(item?.node?.propertyId as string),
    author: by(),
    time: format(new Date(item?.node?.recordedAt as string), 'MMM d, hh:mm:ss a'),
    value: computeValue(item.node.value),
    raw: item.node,
  };
};

export const downloadPropertiesHistory = async (
  range: [Date, Date],
  ids: string[],
  shape?: 'wide' | 'long'
): Promise<void> => {
  const data = {
    ids,
    from: format(range[0], 'yyyy-MM-dd 00:00'),
    to: format(range[1], 'yyyy-MM-dd 23:59'),
    shape: undefined,
  };

  if (shape) {
    data.shape = shape;
  }

  const res = await getObjectPropertiesHistory(data);
  downloadBlob(res.data, `PixelBoard_PropertiesHistory_${data.from}-${data.to}.csv`, res.data.type);
};

export const downloadPropertyHistory = async (range: [Date, Date], ids: string[]): Promise<void> => {
  const data = {
    ids,
    from: format(range[0], 'yyyy-MM-dd 00:00'),
    to: format(range[1], 'yyyy-MM-dd 23:59'),
  };

  const res = await getHistoryProperties(data);
  downloadBlob(res.data, `PixelBoard_PropertyHistory_${data.from}-${data.to}.csv`, res.data.type);
};
