import * as yup from 'yup';

export interface IFormValues {
  message: string;
  tags: string[];
}

export const validationSchema = yup.object({
  message: yup.string().trim().required('Message is required'),
});

export const defaultTagOptions = ['application', 'triggered', 'alert', 'notice', 'message', 'board'];

export const initialValues = {
  tags: ['message', 'board'],
  message: '',
};
