import { gql } from '@src/__generated__';

export const SEARCH_SCHEMA = gql(/* GraphQL */ `
  query loadPure($filter: SchemaFilter) {
    schemata(filter: $filter, orderBy: NAME_ASC) {
      id
      name
    }
  }
`);
