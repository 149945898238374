import { CommonFieldProps } from '@components/create-widget/DataChartColor/constants';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { FieldProps } from 'formik';

import { defaultTagOptions, IFormValues } from '../../constants';
import st from './style.module.css';

export const TagsInput = ({ form, field, label }: FieldProps<IFormValues> & CommonFieldProps) => {
  return (
    <Grid item>
      <Autocomplete
        freeSolo
        multiple
        fullWidth
        value={form.values[field.name]}
        options={defaultTagOptions}
        onChange={(e, value) => {
          form.setFieldValue(field.name, value);
        }}
        classes={{
          option: st.option,
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} variant="filled" color="primary" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => <TextField {...params} variant="standard" label={label} />}
      />
    </Grid>
  );
};
