import { SideLayoutContext } from '@src/context/side-layout/SideLayoutContext';
import { useContext } from 'react';

export const useHistoryLayout = () => {
  const { closeSideLayout, sideLayoutRef } = useContext(SideLayoutContext);

  return {
    closeHistoryLayout: closeSideLayout,
    historyRef: sideLayoutRef,
  };
};
