import { useLazyQuery, useMutation } from '@apollo/client';
import DeleteObjectModal from '@components/DeleteObjectModal';
import EditObjectPropertyGroup from '@components/EditObjectPropertyGroup';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import ChangeAccessModal from '@components/modals/ChangeAccessModal/ChangeAccessModal';
import DateModal from '@components/modals/DateModal';
import EditDevice from '@components/modals/EditDevice';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import { HistoryControlsLayout } from '@components/side-card/history-controls/HistoryControlsLayout';
import useRoute from '@hooks/useRoute';
import { UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import { DASHBOARD_NAMES_QUERY_WITHOUT_COLLECTIONS } from '@modules/dashboards/api/GetDashboardNamesWithoutCollections';
import SendNotificationModal from '@modules/notifications/components/SendNotificationModal';
import { UPDATE_OBJECT } from '@modules/objects/api/updateObject';
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from '@modules/reports/api/ReportsList';
import { Object as ObjectType } from '@src/__generated__/graphql';
import downloadNotifications from '@utils/downloadNotifications';
import grouping from '@utils/groupProperties';
import hideIfEmpty from '@utils/hideIfEmpty';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import useHandleCopy from './handlers/useHandleCopy';
import useMenu from './useMenu';

export default function useObjectMenu() {
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const [unlinkObject] = useMutation(UNLINK_OBJECTS);
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();
  const { collectionId } = useParams();
  const [updateObject] = useMutation(UPDATE_OBJECT);

  const [dashboardsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARD_NAMES_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const getMenu = (object: ObjectType & { linkId?: string }) => {
    if (!object) {
      return [];
    }
    const groups = grouping(object.objectProperties, 'groupName');
    const groupNames = Object.keys(groups);

    return [
      items.genMenuEditItem({
        handleAction: () => {
          EditDevice({
            object,
          }).catch(() => {});
        },
      }),
      items.genMenuAccess({
        handleAction: () => {
          ChangeAccessModal({ object }).catch(() => {});
        },
      }),
      items.getMenuDisableItem({
        enabled: !!object?.enabled,
        handleAction: async () => {
          await updateObject({
            variables: {
              input: {
                id: object.id,
                patch: {
                  enabled: !object?.enabled,
                },
              },
            },
          });
        },
      }),
      items.genMenuSendItem({
        disabled: false,
        handleAction: () => {
          SendNotificationModal({
            object,
          }).catch(() => {});
        },
      }),
      items.genMenuShowHistory({
        handleAction: () => {
          const { dashboardId, reportId, groupId, widgetId } = getParamsByRoute();
          if (dashboardId) {
            navigate(`/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`);
          }

          if (reportId) {
            navigate(`/reports/${reportId}/${groupId}/${widgetId}/${object.id}`);
          }
          HistoryCommonLayout({
            object,
          }).catch(() => {});
        },
      }),
      items.genMenuShowControlsHistory({
        handleAction: () => {
          const { dashboardId, reportId, groupId, widgetId } = getParamsByRoute();

          if (dashboardId) {
            navigate(`/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`);
          }

          if (reportId) {
            navigate(`/reports/${reportId}/${groupId}/${widgetId}/${object.id}`);
          }
          HistoryControlsLayout({
            object,
          }).catch(() => {});
        },
      }),
      items.genMenuDownloadNotifications({
        handleAction: () => {
          DateModal({
            downloadHistory: downloadNotifications,
            downloadIds: [object.id],
          })
            .then()
            .catch(() => {});
        },
      }),
      {
        id: 'divider',
      },
      ...(() =>
        groupNames.map((groupName) =>
          items.genMenuEditPropertyGroup({
            id: `edit-${groupName}`,
            title: `Edit ${groupName}`,
            handleAction: () => {
              EditObjectPropertyGroup({
                group: groups[groupName],
                widgetId: object.id,
              }).catch(() => {});
            },
          })
        ))(),
      {
        id: 'divider',
      },
      items.genCopyUUID({
        handleAction: () => handleCopy({ object }),
      }),
      items.genMenuDelete({
        disabled: false,
        handleAction: () => {
          DeleteObjectModal({
            objectId: object.id,
          }).catch(() => {});
        },
      }),

      ...hideIfEmpty(object.linkId, [
        items.genUnlinkItem({
          handleAction: () => {
            void toast.promise(
              unlinkObject({
                variables: {
                  linkId: object.linkId,
                },
              }).then(async () => {
                if (collectionId) {
                  return Promise.allSettled([dashboardsLazyQuery(), dashboardsWithoutCollectionsLazyQuery()]);
                } else {
                  return Promise.resolve();
                }
              }),
              {
                loading: 'Unlinking object...',
                success: () => 'Object unlinked',
                error: (err) => `${err.toString()}`,
              }
            );
          },
        }),
      ]),
    ];
  };

  return {
    getMenu,
  };
}
