import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { ControlExecution } from '@src/__generated__/graphql';

export const ControlStatusIcon = ({ execution }: { execution: ControlExecution }) => {
  if (execution.ack) {
    if (execution.done && (execution.error === 'false' || execution.error === null)) {
      return <CheckCircleOutlineIcon color="success" />;
    } else if (execution.done && (execution.error === 'true' || execution.error !== null)) {
      return <ErrorOutlineOutlinedIcon color="error" />;
    } else if (!execution.done) {
      return <ReplayOutlinedIcon color="info" />;
    }
  } else {
    return <AccessTimeOutlinedIcon color="primary" />;
  }

  return null;
};
