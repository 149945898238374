import FunctionXIcon from '@components/icons/functionIcon';
import { HistoryCommonLayout } from '@components/side-card/history-common/HistoryCommonLayout';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { hideNull } from '@utils/hideNull';

import { containerClasses } from '../../index';
import st from '../../style.module.css';

interface IProps {
  value: string | number | null;
  id: string;
  item: ObjectType;
}

const ValueItem = ({ value, id, item }: IProps) => {
  const handleCopy = useHandleCopy();

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  return (
    <>
      <ValueMenu
        items={[
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              HistoryCommonLayout({
                object: item,
                propertyId: obj.propId,
              }).catch(() => {});
            },
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: obj.propValue,
                message: 'Value copied',
              });
            },
          },
        ]}
      />
      <ListItem className={st.listItem} classes={containerClasses}>
        <ListItemIcon>
          <FunctionXIcon />
        </ListItemIcon>
        <ListItemText
          onClick={() => {
            handleCopy({
              text: item.id,
              message: 'Copied',
            });
          }}
          primary={<Typography variant="body1">{hideNull(value)}</Typography>}
        />
        <ListItemSecondaryAction className={st.itemToHideOrShow}>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              openValueMenu(e, {
                ...item,
                propValue: value,
                propId: id,
              });
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default ValueItem;
