import ExecuteControl from '@components/modals/ExecuteControl';
import { HistoryControlsLayout } from '@components/side-card/history-controls/HistoryControlsLayout';
import useMenu from '@components/side-card/menu/useMenu';
import tabStyle from '@components/side-card/tab.module.css';
import useMoreMenu from '@components/useMoreMenu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

const TabControlItem = ({ control, controls, item }) => {
  const theme = useTheme();

  const controlArguments = () => {
    return controls.filter((item) => item.rpc !== item.argument).filter((item) => item.rpc === control.rpc);
  };
  const { genExecuteItem, genMenuShowControlsHistory } = useMenu();

  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  return (
    <>
      <MoreMenu
        items={[
          genExecuteItem({
            handleAction: () => {
              ExecuteControl({
                control: control,
                controls: controlArguments(),
                objId: item.id,
              })
                .then()
                .catch(() => {});
            },
          }),
          genMenuShowControlsHistory({
            title: 'Show control history',
            handleAction: () => {
              HistoryControlsLayout({
                object: item,
                historyControlName: control.rpc,
              }).catch(() => {});
            },
          }),
        ]}
        handleMenuItemClick={() => {
          closeMoreMenu();
        }}
      />
      <ListItem
        alignItems="center"
        button
        key={control.id}
        style={{ height: '56px' }}
        classes={{
          container: tabStyle.itemToHover,
        }}
      >
        <ListItemIcon>
          {control.ack === false && <AccessTimeIcon />}

          {control.ack === true && control.done === false && <RefreshIcon />}

          {control.ack === true && control.done === true && control.error === null && <CheckCircleOutlineIcon />}

          {control.ack === true && control.done === true && control.error === true && <ErrorOutlineIcon />}

          {control.ack === undefined && control.done === undefined && control.error === undefined && (
            <AccessTimeIcon style={{ color: theme.palette.wTransparent }} />
          )}
        </ListItemIcon>

        <ListItemText onClick={() => {}} primary={<Typography variant="body1">{control.description}</Typography>} />
        <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
          <IconButton edge="end" onClick={openMoreMenu} size="large">
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default TabControlItem;
